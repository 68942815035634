<template>
  <div class="index_two">
    <div class="index_titel">
      <b data-aos="fade">一站式外贸解决方案</b>
      <div data-aos="fade" data-aos-delay="100">
        打造从获客-运营-转化一站式营销闭环
      </div>
    </div>
    <div class="warp">
      <ul class="tab clearfix">
        <li class="active index_two_1">
          <em data-aos="fade"
            ><img src="~@/assets/home/市场洞察.png" alt=""
          /></em>
          <span data-aos="fade">市场洞察</span>
          <b data-aos="fade"> <img src="~@/assets/home/箭头1.png" alt="" /> </b>
        </li>
        <li class="index_two_2">
          <em data-aos="fade"
            ><img src="~@/assets/home/营销推广.png" alt=""
          /></em>
          <span data-aos="fade">营销推广</span>
          <b data-aos="fade"> <img src="~@/assets/home/箭头3.png" alt="" /> </b>
        </li>
        <li class="index_two_3">
          <em><img src="~@/assets/home/科学决策.png" alt=""/></em>
          <span>科学决策</span>
        </li>
        <li class="index_two_4">
          <em data-aos="fade"
            ><img src="~@/assets/home/精细化客户管理.png" alt=""
          /></em>
          <span data-aos="fade">精细化客户管理</span>
          <b data-aos="fade"> <img src="~@/assets/home/箭头3.png" alt="" /> </b>
        </li>
        <li class="index_two_5">
          <em> <img src="~@/assets/home/综合服务.png" alt=""/></em>
          <span>综合服务</span>
          <b> <img src="~@/assets/home/箭头2.png" alt="" /> </b>
        </li>
        <li class="index_two_6">
          <em><img src="~@/assets/home/智能营销.png" alt=""/></em>
          <span>智能营销</span>
          <b> <img src="~@/assets/home/箭头2.png" alt="" /> </b>
        </li>
        <li class="index_two_7">
          <em data-aos="fade"
            ><img src="~@/assets/home/商机管理.png" alt=""
          /></em>
          <span data-aos="fade">商机管理</span>
          <b data-aos="fade"> <img src="~@/assets/home/箭头5.png" alt="" /> </b>
        </li>
        <li class="index_two_8">
          <em data-aos="fade"
            ><img src="~@/assets/home/自动化培育.png" alt=""
          /></em>
          <span data-aos="fade">自动化培育</span>
          <b data-aos="fade"> <img src="~@/assets/home/箭头4.png" alt="" /> </b>
        </li>
      </ul>
      <div class="tabCon">
        <dl class="clearfix">
          <dd>
            <a>
              <img src="~@/assets/home/数据决策.png" alt="" />
              <span>数据决策</span></a
            >
          </dd>
          <dd>
            <a>
              <img src="~@/assets/home/引流获客.png" alt="" />
              <span>引流获客</span></a
            >
          </dd>
          <dd>
            <a>
              <img src="~@/assets/home/智能营销1.png" alt="" />
              <span>智能营销</span></a
            >
          </dd>
          <dd>
            <a>
              <img src="~@/assets/home/外贸生态1.png" alt="" />
              <span>外贸生态</span></a
            >
          </dd>
        </dl>
        <div class="hidde tabCon1">
          <p data-aos="fade-down">
            <i></i>
            通过市场调研及洞察导向，基于品牌定位及目标群体，提升核心竞争力
          </p>
        </div>
        <div class="hidde tabCon2">
          <p>
            <i></i>
            搭建品牌官网、营销站群、社媒、B2B行业站群多维一体化品牌营销矩阵
          </p>
        </div>
        <div class="hidde tabCon3">
          <p>
            <i></i>
            通过数据全面评估，以业务价值和营销场景为目的，科学助力企业营销决策
          </p>
        </div>
        <div class="hidde tabCon4">
          <p>
            <i></i>
            垂直细分客户，深度挖掘客户潜在需求，简化销售链路，规范业务流程
          </p>
        </div>
        <div class="hidde tabCon5">
          <p>
            <i></i>
            外贸营销策略及知识聚合输出，赋能新业态，持续提供全方位的综合营销服务
          </p>
        </div>
        <div class="hidde tabCon6">
          <p>
            <i></i>
            基于五维分析模型，分层孵化精准触达目标用户，大幅度提升销售转化率
          </p>
        </div>
        <div class="hidde tabCon7">
          <p>
            <i></i>
            双重数据过滤，CRM多渠道自动分配建档加快流转，快速挖掘孵化商机
          </p>
        </div>
        <div class="hidde tabCon8">
          <p>
            <i></i>
            全面集成客户触达工具，对不同画像的客户进行自动化、个性化的培育
          </p>
        </div>
      </div>
    </div>

    <div class="index_two_btn" data-aos="fade-down">
      <a
        @click="showModal"
        data-source="p"
        onmouseover="linkDataSource(this)"
        a-type="register"
        target="_blank"
        rel="noopener noreferrer"
        class="btn_blue"
      >
        <span>免费试用</span></a
      >
      <a @click="showModal" class="btn_white">获取方案</a>
    </div>
    <inquiry-modal-vue
      :visible="visible"
      @cancel="handleCancel"
    ></inquiry-modal-vue>
  </div>
</template>

<script>
// import InquiryModalVue from "@/components/InquiryModal.vue";
import inquiryModalVue from "@/components/InquiryModal2.vue";
export default {
  components: {
    inquiryModalVue,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    showModal() {
      window.open("https://crm.cnabke.com/#/passport/register", "_blank");
      this.visible = true;
      console.log(this.visible, "-----------");
    },

    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.tabCon dl{
  width: 640px;
  margin:0px auto;height: 100px;
  margin-top:35px;  padding-left:35px;
}
.tabCon dl dd{
  float: left;
  width:25%;
  text-align:center;
}
.tabCon dl dd a:hover{
  color:#fff;
  background:#2D97FF
}
.tabCon dl dd a img{
  padding-top:22px;
}
.tabCon dl dd a{
  line-height:26px;
 background: #C8DBFF;
opacity: 0.8;font-family: AlibabaPuHuiTiM, Alibaba-PuHuiTi;
color:#fff;
font-size: 14px;
width: 95px;
height:95px;
border-radius:50%;
display: block;
}
.tabCon dl dd a span{
  display: block;
}
 .index_two{
    height: 1050px;
    background: url('~@/assets/images/背景_全链路数字化出海解决方案.jpg') no-repeat center 80px;
    position: relative;
    margin-top: 100px;
}

  .index_two li{
    position: absolute;
    cursor: pointer;
  }
  .index_two li.index_two_2{
    left: 303px;
    top: -196px;
  }
  .index_two li.index_two_3{
    left: 303px;
    bottom:-396px
  }
  .index_two li.index_two_4{
    left: 736px;
    bottom: -4px;
  }
  .index_two ul.tab{
    position: relative;
    margin-top: 211px;
  }
  .index_two li span,.index_two li b{
    position: absolute;
    display: block;
    color: #2D97FF;
    font-size: 20px;
  }
  .index_two li.index_two_5{
    left: 736px;
    top: 196px;
  }
  .index_two li.index_two_6{
    right: 303px;
    bottom: -396px
  }
  .index_two li.index_two_7{
    right:303px;
    top: -196px
  }
  .index_two li.index_two_8{
    right:0px;
  }
  .index_two_1 b {
    top: -17px;
    left: 159px;
}
.index_two_2 b{
  top: 108px;
    left: 220px;

}
.index_two_4 b{
  top: 108px;
    left: 220px;
}
.index_two_7 b{
  top: 163px;
    left: 168px;
    transform: rotate(68deg);
    -webkit-transform: rotate(68deg);
}
.index_two_8 b{
  left: -108px;
    bottom: -51px;
}
.index_two_6 b{
  bottom: 64px;
    right: 218px;
}
.index_two_5 b{
  bottom: 64px;
    right: 218px;
}
.index_two li em img{
  width: 165px;
  transform: translateZ(0);
    transition: all 0.3s;
}
.index_two li:hover em img{
  transform: scale(1.25);
}

.index_two li span{
  top: 15px;
    left: -67px;
}
.index_two li.index_two_2 span{
  top: 15px;
    left: 156px;
    width: 130px;
}
.index_two li.index_two_4 span{
  top: 15px;
  left: 155px;
    width: 186px;
}
.index_two li.index_two_7 span{
  top: 15px;
    left: 156px;
    width: 130px;
}
.index_two li.index_two_8 span{
  top: 15px;
    left: 156px;
    width: 130px;
}

.tabCon{
  position: relative;
height:0.5px;
}
.tabCon1{
    position: absolute;
    left: -25px;
    top: -120px
}
.tabCon2{
    position: absolute;
    left: 284px;
    top: -340px;
}
.tabCon3{
    position: absolute;
   left: 295px;
    top: 66px;
}
.tabCon4{
    position: absolute;
    left: 704px;
    top: -340px;
}
.tabCon5{
    position: absolute;
   left:735px;
    top: 66px;
}
.tabCon6{
    position: absolute;
   right:305px;
    top: 66px;
}
.tabCon7{
    position: absolute;
   right:305px;
 top: -340px;
}
.tabCon8{
    position: absolute;
   right:5px;
 top: -130px;
}
.index_two .tabCon p{
  font-size: 14px;
  color: #2B68FF;
  width: 200px;
  position: relative;
  background: #FFFFFF;
box-shadow: 0px 6px 13px 0px rgba(60,160,255,0.3000);
border: 1px solid #DCE6FF;
line-height: 22px;    border-radius: 8px;
padding:15px;

}
.index_two .tabCon p i{
  width: 10px;
    position: absolute;
    left: 50%;
    margin-left: -20px;
    bottom: -0.2rem;
    height: 10px;
    display: block;
    line-height: normal;
    border-color: #fff transparent transparent transparent;
    border-style: solid;
    border-width: 0.53333rem 0.53333rem 0 0.53333rem;
}
.index_two_btn{
  width: 484px;
  bottom:100px;
  position: absolute;
  left: 50%;
  margin-left: -242px;
  a span {
    color: #fff;
  }
}
</style>
