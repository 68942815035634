<template>
  <!-- 已服务客户 -->
  <div class="index_seven ">
    <!-- <div class="index_titel">
      <b>已服务客户</b>
      10w+客户的信任与选择
    </div> -->
    <ab-block title="已服务客户" subTitle="10w+客户的信任与选择">
      <div class="warp">
        <div class="index_seven_1">
          <ab-customers></ab-customers>
          <!-- <dl class="ab-logo-list">
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/1.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/2.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/3.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/4.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/5.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/6.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/7.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/8.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/9.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/10.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/11.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/12.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/13.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/14.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/15.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/16.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/17.png" alt=""
                /></a>
              </div>
            </dd>
            <dd>
              <div class="">
                <a href="#" target="_blank"
                  ><img src="~@/assets/logo/18.png" alt=""
                /></a>
              </div>
            </dd>
          </dl> -->
          <!--客户  -->
          <!-- <div class="slider" v-show="show"> -->
          <a-carousel dotsClass="ab-slick-dots">
            <div class="ab-comment">
              <div class="logo">
                <img src="~@/assets/logo/评价语LOGO.png" alt="" />
              </div>
              <div class="content">
                <span class="fontsize18"
                  >之前合作平台推送的询盘很多都是垃圾询盘，客户不够精准，而且费用较高。自从与AB客建立合作之后，我们看到了很多质的改变，不仅解决了询盘不精准的问题，还省去了自己运营的工作，他们为我们过滤掉了垃圾询盘同时，节省了业务员大量的工作时间，还可以挑选自己合适的询盘，降低了我们获取询盘的成本。
                </span>
                <em class="fontsize16">—— 无锡艾维雅纺织品科技有限公司</em>
              </div>
            </div>
            <div class="ab-comment">
              <div class="logo">
                <img src="~@/assets/logo/18.png" alt="" />
              </div>
              <div class="content">
                <span class="fontsize18"
                  >综合了解了很多平台，最后选择了专业程度高、产品体系完善、行业口碑好的AB客，在合作之前帮助我们做了完整的市场分析和推广运营计划，给了我们很多技术和服务上的支持。不仅节约了大量的人力成本，同时也大大的提高了整体运营效率，而且每天都会收到意向询盘，也取得了超预期的效果，他们值得信赖和推荐。
                </span>
                <em class="fontsize16">—— 郑州优科工业设备有限公司</em>
              </div>
            </div>
            <div class="ab-comment">
              <div class="logo">
                <img src="~@/assets/logo/20.jpg" alt="" />
              </div>
              <div class="content">
                <span class="fontsize18"
                  >刚开始确实抱着试一试的心态与AB客建立了合作，反正投入也不多，还比市面上其他产品优惠，渠道和服务项目也多，没想到效果不错，上线没多久就收到了买家询盘，现在积累了不少高质量客户。推送的询盘支持挑选，服务可视化能更清楚的知道做了哪些服务、费用的去向等，相信好的模式经得起市场的考验。
                </span>
                <em class="fontsize16">—— 深圳斯伟亿科技有限公司</em>
              </div>
            </div>
            <div class="ab-comment">
              <div class="logo">
                <img src="~@/assets/logo/19.jpg" alt="" />
              </div>
              <div class="content">
                <span class="fontsize18">
                  上线仅十几天获得了第一个询盘，推送的询盘各个都是高意向客户，没想到第一个询盘成交客户来的如此轻松，AB客提供的平台，不仅提高了我们在外贸同行业中的竞争优势，也为我们拓展了新的营销渠道，大幅度降低了我们的投入还能带来这么好的效果，解决了我们外贸公司难以解决的问题，AB客为了我们重要的合作伙伴。
                </span>
                <em class="fontsize16">—— 无锡优量智能流量仪表有限公司</em>
              </div>
            </div>
          </a-carousel>
          <!-- </div> -->
          <!--客户  -->
        </div>
      </div>
    </ab-block>
  </div>
  <!-- 已服务客户 -->
</template>

<script>
import AbBlock from '@/components/AbBlock.vue';
import AbCustomers from '@/components/AbCustomers.vue';
export default {
  components: {
    AbBlock,
    AbCustomers,
  },
  // props: {
  //   sliderListShow: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  data() {
    return {
      // show: this.sliderListShow,
    };
  },
};
</script>

<style lang="sass" scoped>
  .index_seven{
    background: url(~@/assets/home/index_seven.jpg) top center;
    height: 1100px;
    padding-top: 62px;
    // padding
}
.index_seven_1{
  margin-top: 62px;
  padding-top: 62px;
  padding-bottom: 89px;
  background: #fff;
  border-radius: 8px;
  opacity: 0.95;
}

// .ab-logo-list{
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-items: center;

//   /* display: block; */
//   /* display: inline-block; */
//   flex-wrap: wrap;
//   line-height: 130px
// }

// .index_seven_1 .slider_list li .clearfix{
//   background:#fff;
//   height: 257px;
//   border-bottom-left-radius:8px;border-bottom-right-radius:8px;
// }
// .index_seven_1 dd div img{
//   max-width: 100%
// }
// /* 已服务客户 */

// // .index_seven .warp{
// //   // background: #fff;
// //   border-radius: 8px;
// //   opacity: 0.95;
// // }
// .index_seven_1 dl{
//   padding: 20px 50px;
// }
// .index_seven_1 dd{
//   // float: left;
//   width: 16.666666%;
// }
// .index_seven_1 dd div{
//   text-align: center;
//   border: 1px solid #eee;
//   padding: 8px;
//   // height: 130px;
// }

// .index_seven_1 dd:hover div{
//   box-shadow: 8px 8px 20px 0 rgb(55 99 170 / 10%), -8px -8px 20px 0 #fff, inset 0 4px 20px 0 hsl(0deg 0% 100% / 50%);
// }
// .index_seven_1 .slider{
//   position: relative;
//   padding-top: 40px;
// }
// .index_seven_1 .slider_icon i {
//   display: inline-block;
//   float: none;
//   cursor: pointer;
//   position: relative;
//   line-height: none;
//   width: auto;
//   text-align: center;
//   width: 43px;
//   height: 5px;
//   background: #D8D8D8;
//   border-radius: 2px;
// }
// .index_seven_1 .slider_icon i.btn_act {
//   background: #1a88fe;
// }
// .index_seven_1 .slider_icon i{
//   top:230px;
// }
// .index_seven_1 .slider_icon {
//   background: #fff;
//   height: 290px;
//   text-align: center;
// }
// .index_seven_1 .slider_list li span{
//   float: left;
//   display: block;
//   text-align: right;
//   width: 16%;
//   padding-top: 34px;
// }
// .index_seven_1 .slider_list li span img{
//   width:177px;
// }
// .index_seven_1 .slider_list li em {
//   float: right;  display: block;    text-align: justify;
//   width: 76%;
//   position: relative;
//   padding-right: 60px;
//   font-style: normal;
//   font-size: 18px;
//   color: #666;line-height: 36px;
//   padding-top: 30px;
//   padding-left: 32px;
// }
// .index_seven_1 .slider_list li em i{
//   position:absolute;
//   left: 0px;
//   top:0px;
// }

  .ab-comment{
    padding: 20px 100px;
    // height: 151px;
    display: flex !important;
    align-items: center;

    .logo{
      width: 16%;
      img{
        max-height: 151px;
      }
    }
    .content{
      position: relative;
      width: 84%;
      height: 100%;
      // span {
      //     font-family: AlibabaPuHuiTiM;
      //     font-weight: normal;
      //     color: rgba(51, 54, 70, 0.5);
      // }
      font-family: AlibabaPuHuiTiM;
      font-weight: normal;
      color: rgba(51, 54, 70, 0.5);
    }
    .content em{
      position: absolute;
      right: 0px;
      padding-top: 20px;

    }
  }

  ::v-deep .ant-carousel .slick-dots li button {
    width: 43px;
    height: 5px;
    background: #D8D8D8;
    border-radius: 2px;
    opacity: 1;
  }

  ::v-deep .ant-carousel .slick-dots li.slick-active button{
    background: linear-gradient(230deg, #198BFF 0%, #217AF9 100%);
  }

  ::v-deep .ant-carousel .slick-dots-bottom{
    bottom: -15px;
  }
</style>
