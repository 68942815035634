<template>
  <div class="home_tab_one_container warp">
    <div class="tab_title" data-aos="fade-down" data-aos-delay="50">
      智能营销突破传统桎梏
    </div>
    <div class="home_tab_one">
      <div class="home_tab_one_left" data-aos="fade-down" data-aos-delay="100">
        <div
          :class="
            `${item.className} ${
              tabValue === index
                ? 'home_tab_one_item_active home_tab_one_item'
                : 'home_tab_one_item'
            }`
          "
          v-for="(item, index) in tabList"
          :key="item.title"
          @click="changeTab(item, index)"
        >
          <div class="home_tab_one_item_title">
            <span :class="`${item.icon} iconfont`"></span>
            <div class="home_tab_one_item_title_value">{{ item.title }}</div>
          </div>
          <div v-if="tabValue === index" class="home_tab_one_item_subtitle">
            {{ item.subTitle }}
          </div>
        </div>
      </div>
      <div class="home_tab_one_right" data-aos="fade-down" data-aos-delay="100">
        <div class="home_tab_one_right_img">
          <img :src="currentImg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import img11 from "@/assets/home/截图/11.png";
// import img12 from "@/assets/home/截图/12.png";
// import img2 from "@/assets/home/截图/2.png";
// import img1 from "@/assets/home/截图/1.png";
// import img13 from "@/assets/home/截图/13.png";
// import img14 from "@/assets/home/截图/14.png";

export default {
  components: {},
  data() {
    return {
      tabValue: 0,
      currentImg: "",
      tabList: [
        {
          icon: "icon-a-Frame36324",
          // img: img11,
          className: "iconScale",
          title: "智能助手MAX",
          subTitle: "专业外贸助手大大提高效率",
        },
        {
          icon: "icon-jiankong",
          // img: img12,
          className: "iconScale",
          title: "AI生成营销内容",
          subTitle: "内容创作不再是难题",
        },
        {
          icon: "icon-tradeCapabilities",
          // img: img2,
          className: "iconScale",
          title: "多语种自动翻译",
          subTitle: "不会外语也可以很专业",
        },
        {
          icon: "icon-a-Frame427320434",
          // img: img1,
          title: "智能建站",
          subTitle: "无需专业技能，建高质量SEO标准独立站",
        },
        {
          icon: "icon-share",
          // img: img13,
          className: "iconScale",
          title: "智能推广",
          subTitle: "营销智能体自动创作发帖、发文章",
        },
        {
          icon: "icon-kefu",
          // img: img14,
          title: "智能客服",
          subTitle: "为您提供24/7的不间断服务支持",
        },
      ],
    };
  },
  methods: {
    changeTab(data, e) {
      this.tabValue = e;
      this.currentImg = data.img;
    },
  },
};
</script>

<style lang="sass" scoped>
.home_tab_one_container {
  margin-top: 90px;
  .tab_title {
    margin-bottom: 54px;
    font-weight: 500;
    font-family: AlibabaPuHuiTiM;
font-size: 36px;
color: #111111;
text-align: center;
    }
      .home_tab_one {
        display: flex;
        column-gap: 76px;
        .home_tab_one_left {
          display: flex;
          flex-direction: column;
          row-gap: 30px;
          .iconScale {
            .iconfont {
              transform: scale(1.3);
              }
            }
          .home_tab_one_item {
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            cursor: pointer;
            padding-left: 26px;
            .home_tab_one_item_title {
              font-family: AlibabaPuHuiTiM;
              display: flex;
              column-gap: 10px;
              font-weight: 500;
font-size: 22px;
color: #222222;
align-items: center;
.iconfont {
  // font-size: 24px;
  }
              }
              .home_tab_one_item_subtitle {
  color: #646A73;
  font-size: 16px;
  }
            }
              .home_tab_one_item_active {
                background: #FFFFFF;
box-shadow: 0px 4px 10px 0px rgba(125,150,219,0.1);
border-radius: 12px;
padding: 20px 110px 20px 26px;
color: #0680F2;
.home_tab_one_item_title {
  color: #0680F2;
  }
                }

          }
        .home_tab_one_right {
          flex: 1;
          .home_tab_one_right_img img {
            width:100%;}
          }
        }
  }
</style>
