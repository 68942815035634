<template>
  <div class="ab-system">
    <div class="ab-system-list justify-between">
      <div class="flex-row">
        <div class="ab-system-img">
          <a href="/product/iexpress"
            ><img referrerpolicy="no-referrer" src="~@/assets/home/询盘速递.png"
          /></a>
        </div>
        <!--<div class="ab-system-info flex-col justify-between">-->
          <!--<span>-->
            <!--<router-link class="name" to="/product/iexpress"-->
              <!--&gt;询盘速递</router-link-->
            <!--&gt;-->
          <!--</span>-->
          <!--<span class="desc">扬帆出海计划扶持</span>-->
        <!--</div>-->
        <div class="ab-system-info flex-col justify-between">
          <span>
            <router-link class="name" to="/product/igeek"
            >外贸极客</router-link
            >
          </span>
          <span class="desc">新品上市福利专享</span>
        </div>
      </div>
      <div class="ab-split-line"></div>
      <div class="flex-row  ">
        <div class="ab-system-img">
          <a href="/product/customs">
            <img referrerpolicy="no-referrer" src="~@/assets/home/海关数据.png"
          /></a>
        </div>
        <div class="ab-system-info flex-col justify-between">
          <span>
            <router-link class="name" to="/product/customs"
              >海关数据</router-link
            >
          </span>
          <span class="desc">查询全球贸易信息</span>
        </div>
      </div>
      <div class="ab-split-line"></div>
      <div class="flex-row  ">
        <!-- <div class="group7 flex-col"></div> -->
        <div class="ab-system-img">
          <a href="/product/intelligent-search"
            ><img referrerpolicy="no-referrer" src="~@/assets/home/客户智搜.png"
          /></a>
        </div>
        <div class="ab-system-info flex-col justify-between">
          <span
            ><router-link class="name" to="/product/intelligent-search"
              >客户智搜</router-link
            ></span
          >
          <span class="desc">立即体验注册</span>
        </div>
      </div>
      <div class="ab-split-line"></div>
      <div class="flex-row  ">
        <!-- <div class="group10 flex-col"></div> -->
        <div class="ab-system-img">
          <a href="/product/search-customer">
            <img referrerpolicy="no-referrer" src="~@/assets/home/搜邮寻客.png"
          /></a>
        </div>
        <div class="ab-system-info flex-col justify-between">
          <span>
            <router-link class="name" to="/product/search-customer">
              搜邮寻客
            </router-link>
          </span>
          <span class="desc">领取新用户福利</span>
        </div>
      </div>
      <!-- <div> -->
      <div class="ab-split-line"></div>
      <div class="flex-row">
        <div class="ab-system-img">
          <a href="/product/scrm">
            <img referrerpolicy="no-referrer" src="~@/assets/home/CRM系统.png"
          /></a>
        </div>
        <div class="ab-system-info flex-col justify-between">
          <span>
            <router-link class="name" to="/product/scrm">SCRM</router-link>
          </span>
          <span class="desc">免费注册使用</span>
        </div>
      </div>
    </div>
    <!-- <inquiry-modal-vue
      :visible="visible"
      @cancel="handleCancel"
    ></inquiry-modal-vue> -->
  </div>
  <!-- </div> -->
</template>

<script>
// import InquiryModalVue from '@/components/InquiryModal.vue';
export default {
  components: {
    // InquiryModalVue,
  },
  data() {
    return {
      // visible: false,
    };
  },
  methods: {
    // showModal() {
    //   this.visible = true;
    //   console.log(this.visible, '-----------');
    // },
    // handleCancel() {
    //   this.visible = false;
    // },
  },
};
</script>

<style lang="sass" scoped>

.ab-system{
  height: 140px;
  margin-top: 57px;
  background: rgba(255,255,255,0.7);
  .ab-system-list {
    width: 1620px;
    margin: 0px auto;
    margin-top: 27px;
  }

  .ab-split-line{
    background-color: rgba(216, 216, 216, 0.5);
    width: 2px;
    height: 60px;
    margin-top: 15px;
  }

  .ab-system-img{
    width: 90px;
    height: 90px;
    margin-top: 5px;
  }
  .flex-row{
    cursor: pointer
  }
   .flex-row img{
    transform: translateZ(0);
   transition: all .3s;
  }
  .flex-row:hover {
    span .name{
      color:#1b87fe
    }
    img {
      transform: scale(1.1);
    }

  }

  .ab-system-info{
    height: 55px;
    margin-top: 15px;
    margin-left: 10px;
    .name {
      height: 33px;
      overflow-wrap: break-word;
      color: rgba(51, 54, 70, 1);
      font-size: 24px;
      font-family: AlibabaPuHuiTiM;
      text-align: left;
      white-space: nowrap;
      line-height: 33px;
      display: block;
    }

    .desc {
      height: 22px;
      overflow-wrap: break-word;
      color: rgba(51, 54, 70, 1);
      font-size: 16px;
      font-family: Alibaba-PuHuiTi-R;
      text-align: left;
      white-space: nowrap;
      line-height: 22px;
      display: block;
    }


  }

}
</style>
