<template>
  <div class="home_tab_six_container warp">
    <h2 class="tab_title" data-aos="fade-down" data-aos-delay="50">
      外贸极客能为您带来什么？
    </h2>
    <div class="tab_content">
      <div class="tab_content_item">
        <img
          :src="img1"
          alt="先进的智能化推广引流 为您打造企业名片、提升品牌形象、获得客户询盘"
        />
        <h3 class="tab_content_item_title">先进的智能化推广引流</h3>
        <div class="tab_content_item_desc">
          为您打造企业名片、提升品牌形象、获得客户询盘
        </div>
      </div>
      <div class="tab_content_item">
        <img
          :src="img2"
          alt="集成多渠道工具和数据 为您提供客户线索查找、智能推荐、信息完善"
        />
        <h3 class="tab_content_item_title">集成多渠道工具和数据</h3>
        <div class="tab_content_item_desc">
          为您提供客户线索查找、智能推荐、信息完善
        </div>
      </div>
      <div class="tab_content_item">
        <img
          :src="img3"
          alt="专业的客户开发管理工具 为您提供高效的客户管理、开发、维护"
        />
        <h3 class="tab_content_item_title">专业的客户开发管理工具</h3>
        <div class="tab_content_item_desc">
          为您提供高效的客户管理、开发、维护
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from "@/assets/home/推广引流.png";
import img2 from "@/assets/home/快速获客.png";
import img3 from "@/assets/home/高效成交.png";

export default {
  components: {},
  data() {
    return {
      tabValue: 0,
      img1: img1,
      img2: img2,
      img3: img3,
    };
  },
};
</script>

<style lang="sass" scoped>
.home_tab_six_container {
  padding-bottom: 88px;
  width: 1200px !important;
.tab_title {
  font-weight: 500;
font-size: 42px;
color: #111111;
text-align: center;
margin: 105px 0 55px 0;
  }
    .tab_content {
      display: flex;
      column-gap: 60px;
      .tab_content_item {
        width: 32%;
        text-align: center;
        .tab_content_item_title {
          font-weight: 500;
font-size: 20px;
color: #111111;
margin: 30px 0 10px 0;
          }
            .tab_content_item_desc {
              font-size: 16px;
color: #646A73;
              }
        }
      img {
        width: 90%;
        }
      }
  }
</style>
