<template>
  <div class="">
    <div class="index_four" v-if="false">
      <div class="warp">
        <ul class="tab clearfix" data-aos="fade-down">
          <li class="active">
            <span>01</span> <strong>客户全旅程</strong> <em></em> <i></i>
            <b></b>
          </li>
          <li>
            <span>02</span><strong>全域数字闭环</strong> <em></em> <i></i>
            <b></b>
          </li>
          <li>
            <span>03</span><strong>多维目标管理</strong> <em></em> <i></i>
            <b></b>
          </li>
          <li>
            <span>04</span><strong>人工智能助手</strong> <em></em> <i></i>
            <b></b>
          </li>
          <li>
            <span>05</span><strong>省时省力省心</strong> <em></em> <i></i>
            <b></b>
          </li>
        </ul>
        <div class="tabCon">
          <div class="on hidde ">
            <!-- 客户全旅程 -->
            <div class="clearfix">
              <div class="index_four_tab_l ">
                <i data-aos="fade-right">
                  <img src="~@/assets/home/pic_1.png" alt="" />
                </i>
                <b data-aos="fade-right"
                  >客户全旅程的<br />
                  营销增长</b
                >
                <span data-aos="fade-right">多触点行为轨迹记录</span>
              </div>
              <div class="index_four_tab_r clearfix" data-aos="fade-down">
                <div class="fl ">
                  <b>多触点获取用户信息</b>
                  <span
                    >全面洞察客户需求，构建统一流畅的全旅程体验，用户线索自动记录入库完成建档，智能合并、抓取、关联相关线索信息，基于时间线的客户全触点跟进管理，多视角动态构建客户全景画像。</span
                  >
                  <a
                    @click="showModal"
                    data-source="p"
                    onmouseover="linkDataSource(this)"
                    a-type="register"
                    target="_blank"
                    rel="noopener noreferrer"
                    >免费试用</a
                  >
                </div>
                <div class="fr ">
                  <img src="~@/assets/home/pic_1_1.png" alt="" />
                </div>
              </div>
            </div>
            <!-- 客户全旅程 -->
          </div>
          <div class="hidde">
            <!-- 全域数字闭环 -->
            <div class="clearfix">
              <div class="index_four_tab_l">
                <i> <img src="~@/assets/home/pic_2_2.png" alt="" /> </i>
                <B
                  >客户全旅程的<br />
                  营销增长
                </B>
                <span>多触点行为轨迹记录</span>
              </div>
              <div class="index_four_tab_r clearfix">
                <div class="fl">
                  <b>全域数字链路闭环</b>
                  <span
                    >聚合全球搜索引擎及社媒渠道，告别传统单一推广方式，结合主流营销模型，打造静态人群和动态人群的多场景推广营销需求，快速建立全域数字链路闭环，营销推荐不再受“时空”限制。</span
                  >
                  <a
                    @click="showModal"
                    data-source="p"
                    onmouseover="linkDataSource(this)"
                    a-type="register"
                    target="_blank"
                    rel="noopener noreferrer"
                    >免费试用</a
                  >
                </div>
                <div class="fr">
                  <img src="~@/assets/home/pic_2_1.png" alt="" />
                </div>
              </div>
            </div>
            <!-- 全域数字闭环 -->
          </div>
          <div class="hidde">
            <!-- 多维目标管理 -->
            <div class="clearfix">
              <div class="index_four_tab_l">
                <i> <img src="~@/assets/home/pic_3_2.png" alt="" /> </i>
                <B
                  >客户全旅程的<br />
                  营销增长
                </B>
                <span>多触点行为轨迹记录</span>
              </div>
              <div class="index_four_tab_r clearfix">
                <div class="fl">
                  <b>关键运营指标一目了然</b>
                  <span
                    >通过挖掘、整合、分析客户的静态数据以及动态数据，为客户自动或手动添加状态标签，针对产品、线索、提单、库存等目标一体化管理，智能分配与追踪线索流转，全面提升自动化营销效率。</span
                  >
                  <a
                    @click="showModal"
                    data-source="p"
                    onmouseover="linkDataSource(this)"
                    a-type="register"
                    target="_blank"
                    rel="noopener noreferrer"
                    >免费试用</a
                  >
                </div>
                <div class="fr">
                  <img src="~@/assets/home/pic_3_1.png" alt="" />
                </div>
              </div>
            </div>
            <!-- 多维目标管理 -->
          </div>
          <div class="hidde">
            <!-- 智能化预警提示助手 -->
            <div class="clearfix">
              <div class="index_four_tab_l">
                <i> <img src="~@/assets/home/pic_4_1.png" alt="" /> </i>
                <B
                  >客户全旅程的<br />
                  营销增长
                </B>
                <span>多触点行为轨迹记录</span>
              </div>
              <div class="index_four_tab_r clearfix">
                <div class="fl">
                  <b>智能化预警提示助手</b>
                  <span
                    >基于强大的业务智能化场景应用，通过五维模型集中调度分析，智能预测周边各项风险，实时动态评估事件影响程度，快速提醒采取相关策略，有效降低存在的隐性风险。</span
                  >
                  <a
                    @click="showModal"
                    data-source="p"
                    onmouseover="linkDataSource(this)"
                    a-type="register"
                    target="_blank"
                    rel="noopener noreferrer"
                    >免费试用</a
                  >
                </div>
                <div class="fr">
                  <img src="~@/assets/home/pic_4.png" alt="" />
                </div>
              </div>
            </div>
            <!-- 智能化预警提示助手 -->
          </div>
          <div class="hidde">
            <!-- 省时省力省心 -->
            <div class="clearfix">
              <div class="index_four_tab_l">
                <i> <img src="~@/assets/home/pic_5_1.png" alt="" /> </i>
                <B
                  >客户全旅程的<br />
                  营销增长
                </B>
                <span>多触点行为轨迹记录</span>
              </div>
              <div class="index_four_tab_r clearfix">
                <div class="fl">
                  <b>全站式放心运维托管</b>
                  <span
                    >融合不同行业运营经验、业务目标及营销应用场景，专业运营团队提供全站式放心运维托管服务，简化托管流程，1V1提高整体托管服务质量，降低企业客户运维风险与成本，解决出海企业后顾之忧。</span
                  >
                  <a
                    @click="showModal"
                    data-source="p"
                    onmouseover="linkDataSource(this)"
                    a-type="register"
                    target="_blank"
                    rel="noopener noreferrer"
                    >免费试用</a
                  >
                </div>
                <div class="fr">
                  <img src="~@/assets/home/pic_5.png" alt="" />
                </div>
              </div>
            </div>
            <!-- 省时省力省心 -->
          </div>
        </div>
      </div>

      <div class="index_four_l">
        <img src="~@/assets/home/pic_1.jpg" alt="" />
      </div>
      <div class="index_four_r">
        <img src="~@/assets/home/pic_2.jpg" alt="" />
      </div>
    </div>

    <!--注意：以下注释掉的内容块：全生命周期解决方案 ，这块内容暂时隐藏，待后期页面好了之后再显示出来-->

    <!-- <div class="index_titel">
      <b>全生命周期解决方案</b>
      提供更加垂直的行业解决方案
    </div> -->

    <!--全生命周期解决方案-->
    <!-- <div class="warp">
      <div class="index_five">
        <ul class="tab clearfix">
          <li class="active">
            <a href="#" target="_blank">
              <strong><img src="~@/assets/home/pic_f_1.png" alt=""/></strong>
              <span>工业与机械</span>
              <p><i></i>制造行业</p>
              <p><i></i>水泥行业</p>
              <p><i></i>工业生产</p></a
            >
          </li>
          <li>
            <a href="#" target="_blank"
              ><strong><img src="~@/assets/home/pic_f_2.png" alt=""/></strong>
              <span>电子元器件</span>
              <p><i></i>制造行业</p>
              <p><i></i>水泥行业</p>
              <p><i></i>工业生产</p></a
            >
          </li>
          <li class="index_five_3">
            <a href="#" target="_blank">
              <strong><img src="~@/assets/home/pic_f_3.png" alt=""/></strong>
              <span>服饰与配饰</span>
              <p><i></i>制造行业</p>
              <p><i></i>水泥行业</p>
              <p><i></i>工业生产</p></a
            >
          </li>
          <li>
            <a href="#" target="_blank"
              ><strong><img src="~@/assets/home/pic_f_4.png" alt=""/></strong>
              <span>建筑与服饰</span>
              <p><i></i>制造行业</p>
              <p><i></i>水泥行业</p>
              <p><i></i>工业生产</p></a
            >
          </li>
          <li>
            <a href="#" target="_blank"
              ><strong><img src="~@/assets/home/pic_f_5.png" alt=""/></strong>
              <span>家居日用品</span>
              <p><i></i>制造行业</p>
              <p><i></i>水泥行业</p>
              <p><i></i>工业生产</p></a
            >
          </li>
          <li>
            <a href="#" target="_blank"
              ><strong><img src="~@/assets/home/pic_f_6.png" alt=""/></strong>
              <span>货运及汽车</span>
              <p><i></i>制造行业</p>
              <p><i></i>水泥行业</p>
              <p><i></i>工业生产</p></a
            >
          </li>
          <li class="index_five_3">
            <a href="#" target="_blank"
              ><strong><img src="~@/assets/home/pic_f_7.png" alt=""/></strong>
              <span>包装与办公</span>
              <p><i></i>制造行业</p>
              <p><i></i>水泥行业</p>
              <p><i></i>工业生产</p></a
            >
          </li>
          <li>
            <a href="#" target="_blank"
              ><strong><img src="~@/assets/home/pic_f_8.png" alt=""/></strong>
              <span>化工与能源</span>
              <p><i></i>制造行业</p>
              <p><i></i>水泥行业</p>
              <p><i></i>工业生产</p></a
            >
          </li>
        </ul>
        <div class="tabCon">
          <div class="on hidde ">
            <div class="index_five_tab">
              <div class="index_five_tab_1">
                <div class="index_five_tab_2">
                  <b>工业与机械</b>
                  <span
                    >这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。</span
                  >
                  <p><i class="iconfont icon-dui"></i> 通用解决方案</p>
                  <p><i class="iconfont icon-dui"></i> 行业解决方案</p>
                  <p><i class="iconfont icon-dui"></i> ab客解决方案</p>
                  <a href="">了解详情 ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="hidde ">
            <div class="index_five_tab">
              <div class="index_five_tab_1">
                <div class="index_five_tab_2">
                  <b>电子元器件</b>
                  <span
                    >这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。</span
                  >
                  <p><i class="iconfont icon-dui"></i> 通用解决方案</p>
                  <p><i class="iconfont icon-dui"></i> 行业解决方案</p>
                  <p><i class="iconfont icon-dui"></i> ab客解决方案</p>
                  <a href="">了解详情 ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="hidde ">
            <div class="index_five_tab">
              <div class="index_five_tab_1">
                <div class="index_five_tab_2">
                  <b>服饰与配饰</b>
                  <span
                    >这里是一段方案介绍内容，不同的行业有不同的话术内容，目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。</span
                  >
                  <p><i class="iconfont icon-dui"></i> 通用解决方案</p>
                  <p><i class="iconfont icon-dui"></i> 行业解决方案</p>
                  <p><i class="iconfont icon-dui"></i> ab客解决方案</p>
                  <a href="">了解详情 ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="hidde ">
            <div class="index_five_tab">
              <div class="index_five_tab_1">
                <div class="index_five_tab_2">
                  <b>建筑与服饰</b>
                  <span
                    >这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计不同的话术，解决方案是二期开发内容，目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。</span
                  >
                  <p><i class="iconfont icon-dui"></i> 通用解决方案</p>
                  <p><i class="iconfont icon-dui"></i> 行业解决方案</p>
                  <p><i class="iconfont icon-dui"></i> ab客解决方案</p>
                  <a href="">了解详情 ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="hidde ">
            <div class="index_five_tab">
              <div class="index_five_tab_1">
                <div class="index_five_tab_2">
                  <b>家居日用品</b>
                  <span
                    >这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。这里是容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。</span
                  >
                  <p><i class="iconfont icon-dui"></i> 通用解决方案</p>
                  <p><i class="iconfont icon-dui"></i> 行业解决方案</p>
                  <p><i class="iconfont icon-dui"></i> ab客解决方案</p>
                  <a href="">了解详情 ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="hidde ">
            <div class="index_five_tab">
              <div class="index_five_tab_1">
                <div class="index_five_tab_2">
                  <b>货运及汽车</b>
                  <span
                    >这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。这里是一段方案介绍内发内容，目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。</span
                  >
                  <p><i class="iconfont icon-dui"></i> 通用解决方案</p>
                  <p><i class="iconfont icon-dui"></i> 行业解决方案</p>
                  <p><i class="iconfont icon-dui"></i> ab客解决方案</p>
                  <a href="">了解详情 ></a>
                </div>
              </div>
            </div>
          
          </div>
          <div class="hidde ">
            
            <div class="index_five_tab">
              <div class="index_five_tab_1">
                <div class="index_five_tab_2">
                  <b>包装与办公</b>
                  <span
                    >这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。</span
                  >
                  <p><i class="iconfont icon-dui"></i> 通用解决方案</p>
                  <p><i class="iconfont icon-dui"></i> 行业解决方案</p>
                  <p><i class="iconfont icon-dui"></i> ab客解决方案</p>
                  <a href="">了解详情 ></a>
                </div>
              </div>
            </div>
     
          </div>
          <div class="hidde ">
           
            <div class="index_five_tab">
              <div class="index_five_tab_1">
                <div class="index_five_tab_2">
                  <b>化工与能源</b>
                  <span
                    >这里是一段方案介绍内容，前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。这里是一段方案介绍内容，不同的行业有不同的话术，解决方案是二期开发内容，目前主要是样式设计。</span
                  >
                  <p><i class="iconfont icon-dui"></i> 通用解决方案</p>
                  <p><i class="iconfont icon-dui"></i> 行业解决方案</p>
                  <p><i class="iconfont icon-dui"></i> ab客解决方案</p>
                  <a href="">了解详情 ></a>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </div> -->
    <!--全生命周期解决方案-->
    <!-- 为什么选择AB客？ -->
    <div class="index_six">
      <div class="index_titel  ">
        <b data-aos="fade">为什么选择AB客？</b>
        <div data-aos="fade" data-aos-delay="100">品质保障</div>
      </div>
      <div class="slider">
        <ul class="slider_list " data-aos="fade-down" data-aos-delay="200">
          <li>
            <div class="index_six_1">
              <b>我们的愿景</b>
              <span>
                <p>顺应时代，</p>
                <p>助力外贸企业完成数字化转型，</p>
                <p>让国际贸易变得轻松而简单</p>
                <p>Conform to the times,</p>
                <p>
                  Help foreign trade enterprises complete digital
                  transformation,
                </p>
                <p>Make international trade easy and simple</p>
              </span>
              <em> <img src="~@/assets/home/yj_ico.png" alt="" /> </em>
            </div>
          </li>
          <li>
            <div class="index_six_1 clearfix">
              <dl>
                <dd>
                  <img src="~@/assets/home/s_1.png" alt="" />
                  <span>
                    咨询服务
                    <strong>1V1咨询，迅速立项，确认需求</strong>
                  </span>
                </dd>
                <dd>
                  <img src="~@/assets/home/s_2.png" alt="" />
                  <span>
                    客户成功
                    <strong>丰富经验助力，舒适服务体验</strong>
                  </span>
                </dd>
                <dd>
                  <img src="~@/assets/home/s_3.png" alt="" />
                  <span>
                    极速响应
                    <strong>7*24小时快速响应，在线回答客户问题</strong>
                  </span>
                </dd>
                <dd>
                  <img src="~@/assets/home/s_4.png" alt="" />
                  <span>
                    运维服务
                    <strong>提供全周期线上运维支持服务</strong>
                  </span>
                </dd>
                <dd>
                  <img src="~@/assets/home/s_5.png" alt="" />
                  <span>
                    本地化服务
                    <strong>提供无差异化线下预约服务</strong>
                  </span>
                </dd>
                <dd>
                  <img src="~@/assets/home/s_6.png" alt="" />
                  <span>
                    增值服务
                    <strong>为不同行业细分客户提供服务支持</strong>
                  </span>
                </dd>
              </dl>
            </div>
          </li>
          <li>
            <div class="index_six_2 clearfix">
              <dl>
                <dd>
                  <img src="~@/assets/home/1-3.png" alt="" />
                  <span>
                    <b>智能化建站</b>
                    <strong>
                      <p>采用全新的智能化建站技术架构，</p>
                      <p>有效提高建站效率和质量，</p>
                      <p>全天候站点监测分析，</p>
                      <p>实时优化关键词策略，</p>
                      <p>快速获得自然排名。</p>
                    </strong>
                  </span>
                </dd>
                <dd>
                  <img src="~@/assets/home/2-3.png" alt="" />
                  <span>
                    <b>BI数据决策</b>
                    <strong>
                      <p>基于AI营销数据秒级响应，</p>
                      <p>融合分布式计算，</p>
                      <p>以客户数据中台及各项指标</p>
                      <p>分析为核心，</p>
                      <p>提供情报挖掘、</p>
                      <p>辅助决策、</p>
                      <p>强化营销管理依据。</p>
                    </strong>
                  </span>
                </dd>
                <dd>
                  <img src="~@/assets/home/3-3.png" alt="" />
                  <span>
                    <b>SNS社媒智能化</b>
                    <strong>
                      <p>新一代AI智能定向技术，</p>
                      <p>实时掌握活跃用户行为动态，</p>
                      <p>通过感知模型自主化运营，</p>
                      <p>精准触达用户，</p>
                      <p>从策略到营销全周期陪伴式服务。</p>
                    </strong>
                  </span>
                </dd>
                <dd>
                  <img src="~@/assets/home/4-3.png" alt="" />
                  <span>
                    <b>客户数据中台</b>
                    <strong>
                      <p>鉴于强大的客户数据中台，</p>
                      <p>应用大数据创建客群标签</p>
                      <p>+维度补全+算法引擎，</p>
                      <p>构建目标客户画像模型，</p>
                      <p>打造客户纵向数据资产。</p>
                    </strong>
                  </span>
                </dd>
              </dl>
            </div>
          </li>
          <li>
            <div class="index_six_1">
              <b>资本优势</b>
              <span>
                <p>基于多年对跨境贸易的深耕，</p>
                <p>长期对海外营销与贸易技术的应用与探索，</p>
                <p>
                  以及与诸多国内外各大数据机构的深度合作经历和背后的资本体系，
                </p>
                <p>
                  我们有信心保障AB客的运营、产品、技术、服务等全方位的领先，
                </p>
                <p>同时发挥对外开放的优势，</p>
                <p>积极推动国际贸易生态发展。</p>
              </span>
              <em class="index_six_3 clearfix">
                <i class="index_six_4"
                  ><h2>注册企业数</h2>
                  <h1>11w+</h1>
                </i>
                <i class="index_six_5"
                  ><h2>覆盖行业</h2>
                  <h1>全行业</h1>
                </i>
                <i class="index_six_7"
                  ><h2>年销售额</h2>
                  <h1>7000w+</h1>
                </i>
                <i class="index_six_6"
                  ><h2>专利软著</h2>
                  <h1>40余项</h1>
                </i>
              </em>
            </div>
          </li>
        </ul>
        <div class="slider_icon clearfix" data-aos="fade-down">
          <i class=" btn_act"
            ><span>
              <img src="~@/assets/home/pic_s_1.png" alt="" class="active" />
              <img src="~@/assets/home/pic_s_1_1.png" alt="" class="noactive" />
              数字化愿景</span
            >
            <em></em>
            <strong> </strong>
          </i>
          <i
            ><span>
              <img src="~@/assets/home/pic_s_2.png" alt="" class="active" />
              <img src="~@/assets/home/pic_s_2_2.png" alt="" class="noactive" />
              一站式服务</span
            >
            <em></em>
            <strong> </strong>
          </i>
          <i
            ><span>
              <img src="~@/assets/home/pic_s_3.png" alt="" class="active" />
              <img src="~@/assets/home/pic_s_3_3.png" alt="" class="noactive" />
              智能化技术</span
            >
            <em></em>
            <strong> </strong>
          </i>
          <i
            ><span>
              <img src="~@/assets/home/pic_s_4.png" alt="" class="active" />
              <img src="~@/assets/home/pic_s_4_4.png" alt="" class="noactive" />
              前瞻性资本</span
            >
            <em></em>
            <strong> </strong>
          </i>
        </div>
        <a href="javascript:;" class="arrow prve">
          <span class="slider_left"></span>
        </a>
        <a href="javascript:;" class="arrow next">
          <span class="slider_right"></span>
        </a>
      </div>
    </div>
    <inquiry-modal-vue
      :visible="visible"
      @cancel="handleCancel"
    ></inquiry-modal-vue>
    <!-- 为什么选择AB客？ -->
  </div>
</template>

<script>
import $ from "jquery";
import inquiryModalVue from "@/components/InquiryModal2.vue";
export default {
  components: {
    inquiryModalVue,
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    // $(function() {
    // tan
    $(".tab li").hover(function() {
      $(this)
        .parent(".tab")
        .children("li")
        .eq($(this).index())
        .addClass("active")
        .siblings()
        .removeClass("active");
      $(this)
        .parent(".tab")
        .next(".tabCon")
        .children(".hidde")
        .hide()
        .eq($(this).index())
        .show();
    });
    // 幻灯片
    var count = 0;
    var $li = $(".index_six .slider ul li");
    var $li_1 = $(".index_seven_1 .slider ul li");
    $(".next").click(function() {
      count++;
      if (count == $li.length) {
        count = 0;
      }
      $li
        .eq(count)
        .fadeIn()
        .siblings()
        .fadeOut();
      $(".slider_icon i")
        .eq(count)
        .addClass("btn_act")
        .siblings()
        .removeClass("btn_act");
    });
    $(".prve").click(function() {
      count--;
      if (count == -1) {
        count = $li.length - 1;
      }
      $li
        .eq(count)
        .fadeIn()
        .siblings()
        .fadeOut();
      $(".slider_icon i")
        .eq(count)
        .addClass("btn_act")
        .siblings()
        .removeClass("btn_act");
    });
    $(".slider_icon i").mouseenter(function() {
      $(this)
        .addClass("btn_act")
        .siblings()
        .removeClass("btn_act");
      $li
        .eq($(this).index())
        .fadeIn()
        .siblings()
        .fadeOut();
      $li_1
        .eq($(this).index())
        .fadeIn()
        .siblings()
        .fadeOut();
      count = $(this).index();
    });
    // });
  },
  methods: {
    showModal() {
      window.open("https://crm.cnabke.com/#/passport/register", "_blank");
      this.visible = true;
    },

    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="sass" scoped>



.index_five{
  position: relative;
}
.index_six .index_titel b,.slider_icon i.btn_act span,.index_six_1 b,.index_six_1 p,.index_six_1 dd span,.index_six_2 dd span b,.index_six_2 dd span strong p,.index_six_1 em i h2,.index_six_1 em i h1{
color: #fff
}
.slider_icon i span img{
  width:79px;
}
.index_six_1 em i h2{
  font-size: 18px
}
.index_six_1 em i h1{
  font-size: 40px;
    line-height: 43px;
}
.index_six .index_titel {
    color: rgba(255,255,255,0.4);
}



.index_four {
  position: relative;
  max-width: 1920px;
  margin: 0px auto;
  height: 680px;
}
.index_four_l{
  > img {
    width: 700px;
  }
}
.index_four_l,.index_four_r{
  position: absolute;
  top:0px;
  z-index: 1;

}
.index_four_r{
  left: auto;
  right:0px;

  > img {
    width: 1220px;

  }
}
.index_four .warp{
  z-index: 2;
  position: relative;
}
.index_four li{
  float: left;
}
.index_four .tab li{
    cursor: pointer;
    position: relative;
    font-size: 24px;
    color: rgba(255,255,255,0.5);
}
.index_four .tab li:hover,.index_four .tab li.active{
  color: #fff;
}
.index_four .tab li span{
  font-size: 18px;
  display: block;
  padding-left: 10px;
}
.index_four .tab li strong{
  padding-left: 10px;
  padding-right: 50px;
  font-weight:400;
}
.index_four .tab li em,.index_four .tab li i{
  height: 4px;
  background: rgba(255,255,255,0.5);
  width: 4px;
  border-radius: 50px;
  left: 0px;
  bottom:-1px;
  display: block;
  position: absolute;
}
.index_four .tab li i{
  left: auto;
  right: -4px;
}
.index_four .tab{
  float: right;
  padding-top: 58px;
}
.index_four .tab li b{
  height:2px;
  width: 100%;
  background: rgba(255,255,255,0.5);
  display: block;
  margin-top: 12px;
}
.index_four .tab li.active b,.index_four .tab li.active em,.index_four .tab li.active i{
  background: #fff;
}
.index_four_tab_l{
  float: left;
    width: 33%;
}
.index_four_tab_r{
  float: right;
  width: 66%;
}
.index_four_tab_l i{
  margin-top: 66px;
  display: block;
}
.index_four_tab_l i img{
width:416px;
}
.index_four_tab_l b{
  font-size: 40px;
  color: #333;
  display: block;
  margin-top: 75px;
  padding-bottom: 10px;
}
.index_four_tab_l span{
  color: #666;
  font-size: 18px;
}

.index_four_tab_r .fl b{
  font-size: 40px;
  color: #fff;
  display: block;
  padding-top: 90px;
  padding-bottom: 50px;
  font-weight:400;
}
.index_four_tab_r .fl span{
  font-size: 18px;
  color: rgba(255,255,255,0.8);
  line-height: 28px;
  width: 470px;
  display: block;
}
.index_four_tab_r .fl a{
  border-radius: 4px;
  border: 2px solid #FFFFFF;
  height: 56px;
  color: #fff;
  margin-top: 60px;
  width: 188px;
  display: block;
  font-size: 18px;
  text-align: center;
  line-height: 56px;
}
.index_four_tab_r .fl{
  padding-left: 80px;
}
.index_four_tab_r .fl a:hover{
  background: #fff;
  color: #2484fc;
}
.index_four_tab_r .fr{
  padding-top: 100px;
  width: 48%;
  text-align: right;
}
.index_five ul{
  margin: 0px -20px;
}
.index_five li{
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2000);
  border-radius: 6px;
  border: 1px solid #def1ff;
  float: left;
  height: 256px;
  position: relative;
  width: 240px;
  margin: 20px;
  cursor:pointer;    margin-bottom: 50px;
}
.index_five li strong{
  width: 100px;
  position: absolute;
  left:50%;
  margin-left: -50px;
  top: -44px;cursor: pointer;
}
.index_five li strong img{
  width:100%
}
.index_five li span{
  font-size: 22px;
  display: block;
  text-align: center;
  padding-top: 75px;
  padding-bottom: 25px;cursor: pointer;
}
.index_five li p{
  font-size: 18px;
  color: rgba(0,0,0,0.3);
  padding-bottom: 8px;cursor: pointer;
  margin-bottom:0px;
}
.index_five li p i{
  width: 6px;
  margin-left: 66px;
height: 6px;
background: linear-gradient(230deg, #198BFF 0%, #217AF9 100%);
display: inline-block;
border-radius: 50px;
margin-right: 10px;
top: -4px;
position: relative;
}
.index_five li:hover{
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
  border: 1px solid rgb(0 100 242);

}.index_five li:hover p{
  color: #333
}
.index_five_tab{
  width: 457px;
  height: 620px;
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2000);
  border-radius: 8px;
  border: 12px solid #fff;
  overflow: hidden;
  position: absolute;
  top: -14px;
  left:570px
}
.index_five_tab_1{
  background: url(~@/assets/home/pic_3.jpg) no-repeat;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background-size:cover;

}
.index_five li.index_five_3.active,.index_five li.index_five_3{
  margin-left: 552px;
}
.index_five_tab_1 b{
  font-size: 26px;
  font-weight:400;
  line-height: 35px;
  display: block;
  color: #fff;
  padding-bottom: 20px;
}
.index_five_tab_2{
  padding: 35px;
}
.index_five_tab_1 span{
  line-height: 30px;text-align: justify;
  font-size: 18px;
  color: rgba(255,255,255,0.7);
  display: block;
  height: 300px;
  overflow: hidden;
}.index_five_tab_1 p i{
font-size:24px;
  padding-right: 4px;
  vertical-align: top;
position: relative;
display: inline-block;
top: 4px;
}
.index_five_tab_1 p,.index_five_tab_1 a{
font-size: 16px;
color: #FFFFFF;
line-height: 22px;
padding:10px 0px;
display: block;
}
.index_five_tab_1 a{
  padding-top: 15px;
  color: #fff
}
.index_five_tab_1 a:hover{
  text-decoration: underline;
}
.index_four_tab_r .fr img{
  max-width: 100%
}
.index_six{
  background: url(~@/assets/home/pic_4.jpg) top center repeat-x #242c43;
    font-family: 'AlibabaPuHuiTiM';
}

.index_six_1,.index_six_2{
  padding-top: 300px;
  padding-left: 180px;
}
.index_six_1 b{
  font-size: 40px;
  display: block;
  padding-bottom: 50px;
  font-weight:400
}
p{
  margin-bottom:0px;
}
.index_six_1 p,.index_six_2 dd span strong p,.index_six_1 em i{
  font-size: 18px;
  line-height: 38px;

}
.index_six_1 em{
  position: absolute;
  right: 160px;
  top: 262px;
}
.index_six_1 em img{
width:553px;
}
.index_six_1 dd{
  width: 33.333%;
  float: left;
      display: flex;
      padding-bottom: 97px

}
.index_six_1 dd img{
  display: flex;
  height: 70px;
}
.index_six_1 dd span{
  font-size: 26px;
  padding-left: 34px;
  padding-top: 5px;
}
.index_six_1 dd span strong{
  font-size: 18px;
    padding-top: 8px;
  color: rgba(255, 255, 255, 0.6);    display: block;
}
.index_six_2 dd{
  float: left;
  width: 25%;
  display: flex;
}
.index_six_2 dd img{
  display: flex;
  margin-right: 15px;
  height: 25px;
}
.index_six_2 dd span b{
  font-size: 30px;
    display: block;
    margin-top: -8px;
    margin-bottom: 46px;
    font-weight:400
}
.index_six_1 em.index_six_3{
  width: 671px;    top: 312px;    right:60px;
  height: 260px
}
.index_six_3 i{
  width: 49%;
  float: left;
}
.index_six_4,.index_six_5{
  border-bottom: 1px solid rgba(255,255,255,.1);
}
.index_six_4{
  border-right: 1px solid rgba(255,255,255,.1);
}
.index_six_6{
  border-left: 1px solid rgba(255,255,255,.1);
}
.index_six_3  h1{
  padding-left: 20px;
  padding-bottom: 25px;
}
.index_six_3 h2{
  padding-left: 20px;
  padding-bottom: 20px;
}
.index_six_3 i.index_six_5 h2,.index_six_3 i.index_six_5 h1,.index_six_3 i.index_six_6 h2,.index_six_3 i.index_six_6 h1{
  padding-left: 50px;
}
.index_six_3 i.index_six_6 h2,.index_six_3 i.index_six_7 h2{
  padding-top: 40px;
}

/* 幻灯片 */

.index_six .slider {
  width: 1620px;
  height: 912px;
  margin: 0px auto;
  margin-top: 60px;
  position: relative;
}
.slider_list li {
  position: absolute;
  display: none;
  width: 100%;
}
.slider_list li:first-child {
  display: block;
}
.slider_icon {
  background: #fff;
  border-radius: 10px;
  height: 170px;
}
.slider_icon i {
  display: block;
  float: left;
  cursor: pointer;
  position: relative;
  line-height: 165px;
  width: 25%;
  text-align: center;
}
.slider_icon i.btn_act .active,
.slider_icon i.btn_act strong {
  display: inline-block;
}
.slider_icon i.btn_act strong {
  width: 22px;
  position: absolute;
  left: 50%;
  margin-left: -10px;
  bottom: -15px;
  height: 11px;
  display: block;
  line-height: normal;
   border-color: #3269ff   transparent transparent transparent;
            border-style: solid;
            border-width: 20px 20px 0 20px;
}
.slider_icon i:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.slider_icon i:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.slider_icon i .active,
.slider_icon i.btn_act .noactive,
.slider_icon i strong {
  display: none;
}
.slider_icon i.btn_act em {
  background: #3269ff;
}
.slider_icon i em {
  height: 4px;
  width: 40px;
  background: #fff;
  position: absolute;
  bottom: -530px;
  left: 180px;
}
.slider_icon i:nth-child(2) em {
  left: -185px;
}
.slider_icon i:nth-child(3) em {
  left: -550px;
}
.slider_icon i:nth-child(4) em {
  left: -915px;
}
.arrow {
  width: 88px;
  height: 88px;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 60%;
  margin-top: -30px;
  border-radius: 50%;
}
.prve {
  left: -100px;
}
.next {
  right: -100px;
}
.arrow span {
  display: block;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
  border-left: 2px solid rgba(255, 255, 255, 0.4);
}
.slider_left {
  margin: 38px 0 0 38px;
  transform: rotate(45deg);
}
.slider_right {
  margin: 38px 0 0 35px;
  transform: rotate(-135deg);
}
.arrow:hover {
  background: #161b2a;
}
.slider:hover .arrow {
  display: block;
}
.slider_icon i.btn_act {
  background: #3269ff;
}
.slider_icon i span {
  font-size: 30px;
}
.slider_icon i span img {
  vertical-align: middle;
  padding-right: 10px;
}
</style>
