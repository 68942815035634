<template>
  <div class="home_tab_four_container">
    <div class="tab_title" data-aos="fade-down" data-aos-delay="50">
      智能助手&知识经验
    </div>
    <div class="home_tab_four warp">
      <div
        class="home_tab_four_label"
        data-aos="fade-down"
        data-aos-delay="100"
      >
        您是否曾困惑于外贸业务中的种种复杂流程？是否曾为缺乏经验而错失商机？
        智能助手，基于尖端AI技术，能够智能对话、答疑解惑，带您轻松掌握外贸知识。
        借助其内置的经验知识模块，您将获得前沿的营销策略、实时更新的行业资讯和丰富的分享交流平台。
        无论是初入职场的新手，还是希望提升技能的老手，智能助手都能有效降低经验门槛，让您迅速上手，事半功倍。
      </div>
      <div class="home_tab_four_img" data-aos="fade-down" data-aos-delay="150">
        <img src="@/assets/home/截图/6.png" />
        <img src="@/assets/home/截图/5.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
.home_tab_four_container {
  margin-top: 90px;
  .tab_title {
    font-weight: 500;
    font-family: AlibabaPuHuiTiM;
font-size: 36px;
color: #111111;
text-align: center;
    }
      .home_tab_four_label {
        // margin: 25px 0 48px 0;
        font-size: 18px;
color: #646A73;
line-height: 38px;
text-align: center;
white-space: break-spaces;
        }
          .home_tab_four_img {
            display: flex;
            column-gap: 20px;
            img {
              flex: 1;
              width: 50%;
              }
            }
  }
</style>
