<template>
  <div class="product_tab_container">
    <a-affix
      :offset-top="120"
      style="z-index: 999"
      class="product_tab_affix"
      v-if="false"
    >
      <div class="product_tab" data-aos="fade-down" data-aos-delay="50">
        <div class="warp product_tab_list">
          <a-anchor @change="changeTab" :targetOffset="100">
            <div
              v-for="item in tabList"
              :key="item.title"
              :class="
                `${
                  tabValue === '#' + item.anchorId
                    ? 'product_tab_item_active product_tab_item'
                    : 'product_tab_item'
                }`
              "
            >
              <span :class="`${item.icon} ${item.iconClass} iconfont`"></span>
              <a-anchor-link :href="`#${item.anchorId}`" :title="item.title" />
            </div>
          </a-anchor>
        </div>
      </div>
    </a-affix>

    <div
      :class="`${item.className} product_tab_one_container productCommonTab`"
      v-for="item in dataList"
      :key="item.title"
      :id="item.anchorId"
    >
      <h2 class="product_tab_one_blockTitle" v-if="item.blockTitle">
        {{ item.blockTitle }}
      </h2>
      <div class="warp">
        <div class="product_tab_one">
          <div
            class="product_tab_one_left"
            data-aos="fade-down"
            data-aos-delay="50"
          >
            <img :src="item.img" :alt="item.alt" />
          </div>
          <div class="product_tab_one_right">
            <div
              class="product_tab_one_title"
              data-aos="fade-down"
              data-aos-delay="50"
              v-if="item.title && item.desc"
            >
              <h2 class="product_tab_one_title_value">
                {{ item.title }}
              </h2>
              <div class="product_tab_one_desc">
                {{ item.desc }}
              </div>
            </div>
            <div
              class="product_tab_one_subtitle"
              v-for="item in item.subList"
              :key="item.title"
              data-aos="fade-down"
              data-aos-delay="100"
            >
              <h3 class="product_tab_one_subtitle_value">{{ item.title }}</h3>
              <p class="product_tab_one_desc" v-html="item.desc"></p>
            </div>
            <div
              @click="showModal"
              class="product_tab_one_btn"
              v-if="item.button"
            >
              立即体验
            </div>
            <div
              class="product_tab_one_tagtitle"
              data-aos-delay="150"
              v-if="item.tags.length"
            >
              相关功能
            </div>
            <div
              class="product_tab_one_tag"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              <div
                class="product_tab_one_tag_item"
                v-for="item in item.tags"
                :key="item"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <inquiry-modal-vue
      :visible="visible"
      @cancel="handleCancel"
    ></inquiry-modal-vue>
  </div>
</template>

<script>
import inquiryModalVue from "@/components/InquiryModal2.vue";

export default {
  components: {
    inquiryModalVue,
  },
  props: {
    dataList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      visible: false,
      tabValue: "#anchor1",
      currentInfo: {},
      tabList: [
        {
          icon: "icon-a-Frame36324",
          title: "智能助手",
          anchorId: "anchor1",
        },
        {
          icon: "icon-jiankong",
          title: "营销内容",
          anchorId: "anchor2",
        },
        {
          icon: "icon-share",
          title: "经验策略",
          anchorId: "anchor3",
        },
        {
          icon: "icon-a-Frame427320434",
          title: "智能建站",
          anchorId: "anchor4",
          iconClass: "scaleMiniIcon",
        },
        {
          icon: "icon-a-Frame427320246",
          title: "社媒运营",
          anchorId: "anchor5",
          iconClass: "scaleLargeIcon",
        },
        {
          icon: "icon-wangzhanjiance",
          title: "内容营销",
          anchorId: "anchor6",
        },
        {
          icon: "icon-zhinengsousuo1",
          title: "客户搜索",
          anchorId: "anchor7",
        },
        {
          icon: "icon-a-Frame36377",
          title: "客户管理",
          anchorId: "anchor8",
        },
      ],
    };
  },
  created() {
    // this.changeTab(this.tabList[0], 0);
  },
  methods: {
    showModal() {
      window.open("https://crm.cnabke.com/#/passport/register", "_blank");
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
    },
    changeTab(link) {
      this.tabValue = link;
      console.log("Anchor:OnChange", link);
    },
  },
};
</script>

<style lang="sass">
.product_tab_container {
  background: #F7FAFF;
  font-family: "Alibaba-PuHuiTi-R";
  // padding-top: 50px;
  .warp {
    width: 1300px !important;
    }
  .product_tab_affix {
    .ant-affix {
      top: 120px !important;
      }
    }
  .product_tab {
    border-bottom: 1px solid #E9E9E9;
    margin-bottom: 50px;
    background: #F7FAFF;
    .product_tab_list {
      display: flex;
      column-gap: 50px;
      justify-content: center;

      .ant-anchor-wrapper {
        background: none;}
      .ant-anchor {
        display: flex;
        column-gap: 50px;
        justify-content: center;
        .ant-anchor-ink {
          display: none;}
          .ant-anchor-link {
            padding: 0px;
            }
        }
      .product_tab_item {
        display: flex;
        column-gap: 10px;
        align-items: center;
        font-size: 18px;
color: #646A73;
cursor: pointer;
padding-bottom: 10px;
        .iconfont {
          font-size: 24px;
          }
            .scaleMiniIcon {
              transform: scale(0.8);
              }
              .scaleLargeIcon {
              transform: scale(1.2);
              }
        }
          .product_tab_item_active {
            color: #0680F2;
            border-bottom: 2px solid #0680F2;
            }
      }
    }
      .whiteBg {
        background: #fff;
        }
          .reverse {
            .product_tab_one {
              flex-direction: row-reverse;
              }
            }
      .productCommonTab {
        padding: 88px 0 100px 0;
        .product_tab_one_blockTitle {
          font-weight: 500;
font-size: 36px;
color: #111111;
text-align: center;
margin-bottom: 40px;
          }
        .product_tab_one {
          display: flex;
          column-gap: 60px;
          align-items: center;
          .product_tab_one_left {
            // width: 45%;
            flex: 1;
            img {
              width: 100%;
              // box-shadow: 0px 3px 20px 0px rgba(22,45,95,0.1);
              }
            }
            .product_tab_one_right {
            // width: 55%;
            flex: 1;
            .product_tab_one_title {
              margin-bottom: 40px;
              .product_tab_one_title_value {
                font-family: AlibabaPuHuiTiM;
                font-weight: 500;
font-size: 36px;
color: #111111;
                }

              }
                .product_tab_one_btn {
                  width: 180px;
height: 44px;
background: #0680F2;
border-radius: 4px;
font-size: 16px;
color: #FFFFFF;
text-align: center;
line-height: 44px;
cursor: pointer;
margin: 30px 0 ;
                  }
                .product_tab_one_subtitle {
                  margin-bottom: 20px;
                  .product_tab_one_subtitle_value {
                    font-family: AlibabaPuHuiTiM;
                    font-weight: 500;
font-size: 20px;
color: #111111;
                    }

                  }
                .product_tab_one_desc {
                  font-size: 16px;
color: #646A73;
line-height: 29px;
margin-top: 10px;
strong {
  color: #111111;}
div {
  margin-top: 20px;
  }
                  }
                    .product_tab_one_tagtitle {
                      font-size: 12px;
color: #999999;
margin-top: 40px;
margin-bottom: 8px;
                      }
                        .product_tab_one_tag {
                          display: flex;
                          column-gap: 10px;
                          .product_tab_one_tag_item {
                            background: #E8EFFF;
border-radius: 4px;
font-size: 14px;
color: #000000;
padding: 5px 16px;

                            }
                          }
            }
          }
        }
  }
</style>
