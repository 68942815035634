<template>
  <div class="home_tab_four_container">
    <div class="tab_title" data-aos="fade-down" data-aos-delay="50">
      服务支持&服务报告
    </div>
    <div class="home_tab_four warp">
      <div
        class="home_tab_four_label"
        data-aos="fade-down"
        data-aos-delay="100"
      >
        优质专业的服务和客户满意一直是我们追求的目标，从您接触我们的那一刻起，我们的团队将为您提供丰富知识、经验分享、即时问题解答和深入探讨交流。
        为了确保您获得卓越的营销效果，我们每月向您提供详尽的服务报告，透明展示整个服务过程。
        通过专业的数据分析与反馈，我们的团队会为您量身定制优化策略，力求每一环节都贴心、优质。
        我们的服务不仅可靠，更能与您建立深度合作，共同推动业务增长。
      </div>
      <div class="home_tab_four_img" data-aos="fade-down" data-aos-delay="150">
        <img src="@/assets/home/截图/4.png" />
        <img src="@/assets/home/截图/3.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
.home_tab_four_container {
  margin-top: 90px;
  .tab_title {
    font-weight: 500;
    font-family: AlibabaPuHuiTiM;
font-size: 36px;
color: #111111;
text-align: center;
    }
      .home_tab_four_label {
        // margin: 25px 0 48px 0;
        font-size: 18px;
color: #646A73;
line-height: 38px;
text-align: center;
white-space: break-spaces;
        }
          .home_tab_four_img {
            display: flex;
            column-gap: 20px;
            img {
              flex: 1;
              width: 50%;
              }
            }
  }
</style>
