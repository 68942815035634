import { render, staticRenderFns } from "./TabFive.vue?vue&type=template&id=8ba2804a&scoped=true"
import script from "./TabFive.vue?vue&type=script&lang=js"
export * from "./TabFive.vue?vue&type=script&lang=js"
import style0 from "./TabFive.vue?vue&type=style&index=0&id=8ba2804a&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ba2804a",
  null
  
)

export default component.exports