<template>
  <div class="page">
    <div class="header flex-col header-bg">
      <div class="ab-header-home flex-col">
        <!--顶部导航-->
        <MainNav />
        <Banner />
      </div>
    </div>
    <div
      class="banner_bottom_bg"
      style="background: rgb(245,249,254,0.3);height: 170px;"
    ></div>
    <TabSix />
    <ProductTab :dataList="dataList" />
    <!-- <div class="view_bg_top"></div>
    <div class="view_bg"></div> -->
    <!-- <HomeTab /> -->
    <!-- <Saas /> -->
    <!-- <Digit /> -->
    <Ifive />

    <ServingCustomers sliderListShow="true"></ServingCustomers>
    <FooterBanner />
    <inquiry-modal-vue
      :visible="visible"
      @cancel="handleCancel"
    ></inquiry-modal-vue>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
// @ is an alias to /src
import MainNav from "@/components/MainNav.vue";
import Footer from "@/components/Footer.vue";
import FooterBanner from "@/components/FooterBanner.vue";
import ServingCustomers from "@/components/ServingCustomers.vue";
import Banner from "./Home/Banner.vue";
import System from "./Home/System.vue";
import Saas from "./Home/Saas.vue";
import Digit from "./Home/Digit.vue";
import Ifive from "./Home/Ifive.vue";
import HomeTab from "./Home/HomeTab.vue";
import metaInfo from "@/metaInfo";
import inquiryModalVue from "@/components/InquiryModal2.vue";
import icon9 from "@/assets/home/截图/9.png";
import icon8 from "@/assets/home/截图/8.png";
import icon6 from "@/assets/home/截图/6.png";
import icon7 from "@/assets/home/截图/7.png";
import ProductTab from "@/views/Product/components/ProductTab.vue";
import TabSix from "@/views/Home/TabSix.vue";

export default {
  name: "Home",
  metaInfo: metaInfo["home"],
  data() {
    return {
      visible: false,
      dataList: [
        {
          img: icon9,
          anchorId: "anchor1",
          className: "reverse",
          button: true,
          blockTitle: "一站式推广引流解决方案，助您高效获客，提升转化率!",
          alt:
            "一站式推广引流解决方案，包含智能SEO优化、社交媒体集中管理、AI内容营销和24/7多语言智能客服，帮助提高网站流量、品牌互动和客户转化率。",
          subList: [
            {
              title: "智能SEO优化",
              desc: "全面提升网站搜索引擎排名、流量与转化。",
            },
            {
              title: "社媒一站管理",
              desc:
                "Facebook、Linkedin等多账号集中管理，增强品牌互动及宣传效果。",
            },
            {
              title: "智能内容营销",
              desc:
                "Al全年无休自动创作发布网站文章和社媒动态，增强品牌和流量。",
            },
            {
              title: "24/7智能客服",
              desc: "多语言全天候支持，提高客户满意度和询盘量。",
            },
          ],
          tags: [],
        },
        {
          img: icon7,
          className: "whiteBg",
          blockTitle: "极速获取潜在客户，让您遥遥领先！",
          button: true,
          anchorId: "anchor2",
          alt:
            "极速获取潜在客户解决方案，包含社媒实时监控、多源客户搜索、智能客户推荐及数据智能完善，帮助企业快速发现全球商机，提升获客效率与精准度。",
          subList: [
            {
              title: "社媒实时监控",
              desc: "实时捕捉全球精准商机，抢占市场先机。",
            },
            {
              title: "多源客户搜索",
              desc:
                "通过海关数据、企业信息、邮箱查询、Google地图等多渠道快速找到精准客户。",
            },
            {
              title: "智能客户推荐",
              desc: "每天自动匹配推荐全球优质客户，提高获客效率和精准度。",
            },
            {
              title: "数据智能完善",
              desc: "丰富客户资料，提升营销转化。",
            },
          ],
          tags: [],
        },
        {
          img: icon6,
          className: "reverse",
          button: true,
          anchorId: "anchor3",
          blockTitle: "全方位客户管理，助力业务腾飞",
          alt:
            "全方位客户管理解决方案，涵盖高效线索管理培育、全面商机管理开发、售后服务支持及多种通讯工具集成，帮助企业提升客户转化率、忠诚度和互动满意度。",
          subList: [
            {
              title: "高效线索管理培育",
              desc: "集中特定资源，高潜力客户转化率显著提升。",
            },
            {
              title: "全面商机管理开发",
              desc: "商机跟踪无遗漏，提高谈判成功率。",
            },
            {
              title: "快速售后服务支持",
              desc: "快速解决客户问题，提升客户忠诚度和复购率。",
            },
            {
              title: "多种通讯工具集成",
              desc: "集成全球多种通讯方式，提高客户互动满意度。",
            },
          ],
          tags: [],
        },
        {
          img: icon8,
          blockTitle: "高效智能营销管理，让全球贸易从未如此简单",
          className: "whiteBg",
          button: true,
          anchorId: "anchor4",
          alt:
            "高效智能营销管理平台，提供AI辅助、自动生成多语言内容、高效团队管理与数据驱动决策支持，简化全球贸易营销流程，提高工作效率。",
          subList: [
            {
              title: "智能营销助手",
              desc: "AI辅助提升全方位工作效率。",
            },
            {
              title: "内容轻松生成",
              desc: "自动生成高质量、多语言营销内容。",
            },
            {
              title: "高效团队管理",
              desc: "随时随地管理，无缝云端集成，提升团队协作效率。",
            },
            {
              title: "知识经验决策",
              desc: "数据驱动决策，轻松获得营销知识和经验，提升营销效果。",
            },
          ],
          tags: [],
        },
      ],
    };
  },
  methods: {
    showModal() {
      window.open("https://crm.cnabke.com/#/passport/register", "_blank");
      this.visible = true;
    },

    handleCancel() {
      this.visible = false;
    },
  },
  // metaInfo: {
  //   title:
  //     'A全链路数字化出海解决方案-智能外贸营销管理平台AB客-全链路数字化出海解决方案-智能外贸营销管理平台',
  //   titleTemplate: 'AB客-%s',
  //   htmlAttrs: {
  //     lang: 'en',
  //     amp: true,
  //   },
  //   meta: [
  //     {
  //       name: 'keywords',
  //       content:
  //         '询盘速递,询盘服务,品牌营销,海关数据,客户推荐,搜邮寻客,客户智搜,CRM,外贸营销,外贸营销推广获客,外贸建站',
  //     },
  //     {
  //       name: 'description',
  //       content:
  //         'AB客是以“大数据+人工智能”为外贸企业打造的SaaS智能外贸营销管理平台,为外贸企业提供网站建设|品牌整合营销|数据挖掘及智能客户管理等全旅程数字化产品,帮助外贸企业构建多元化外贸数字营销服务体系',
  //     },
  //   ],
  // },
  components: {
    MainNav,
    Footer,
    Banner,
    System,
    Saas,
    Digit,
    Ifive,
    FooterBanner,
    ServingCustomers,
    HomeTab,
    inquiryModalVue,
    ProductTab,
    TabSix,
  },
};
</script>
<style lang="sass">
.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 100%;
  /* height: 7626px; */
  overflow: hidden;
  .view_bg_top {
    background: linear-gradient( 180deg, #FFFFFF 0%, rgba(235, 244, 255, 0.3) 100%);
    height: 150px;
    }
  .view_bg {
    background: url('~@/assets/home/流量.png') ;
  background-size: cover;
  height: 1000px;
    }
}

.header {
  background-size: cover;
  -webkit-transition: all 1s linear; transition: all 0.5s linear;
}
.tradeHeader {
  background: url('~@/assets/home/外贸极客_bg.png') ;
  background-size: cover;
  -webkit-transition: all 1s linear; transition: all 0.5s linear;
}
.blueHeader {
  background: url('~@/assets/images/header-bg-blue.png');
  background-size: cover;
.paragraph1, .word6 {
  color: #fff;
}
.word6 {
  font-family: AlibabaPuHuiTiM;
  font-size: 24px;
}
.section1 {
  background: #fff;
}
.section1:hover {
  background: #fff;
}
.banner_img {
  width: 900px !important;
}
.txt4 {
  font-size: 32px;
  width: auto;
  text-align: center;
  margin: 0px;
  line-height: 64px;
}
.slick-slider .slick-active {
button {
  background: #fff !important;
}
}
.nav .ant-menu-item-selected {
  border-color: #fff;
}
.nav-login-btn {
  border-color: #fff;
  color: #fff;
}
.nav-register-btn {
  background: #fff;
  color: rgb(12, 80, 240);
}
.ant-affix .nav {
  background: rgb(12, 80, 240);
}
.nav-bar {
.ant-menu-item a {
  color: #fff;
}
.ant-menu-submenu-title {
  color: #fff;
}
}
.nav-logo-white {
  display: inline-block;
}
.nav-logo {
  display: none;
}
}
.redHeader {
  background: url('~@/assets/images/header-bg.png');
  background-size: cover;
  .slick-slider .slick-active {
    button {
      background: #fff !important;
    }
  }
.nav .ant-menu-item-selected {
  border-color: #fff;
}
.nav-login-btn {
  border-color: #fff;
  color: #fff;
}
.nav-register-btn {
  background: #fff;
  color: rgb(230, 70, 60);
}
.ant-affix .nav {
  background: rgb(230, 90, 70);
}
.nav-bar {
.ant-menu-item a {
  color: #fff;
}
  .ant-menu-submenu-title {
    color: #fff;
  }
}
.nav-logo-white {
  display: inline-block;
}
.nav-logo {
  display: none;
}
}
.box3 {
  width: 549px;
  height: 487px;
  margin-top: 12px;
}

.paragraph1 {
  width: 394px;
  overflow-wrap: break-word;
  color: rgba(51, 54, 70, 1);
  font-size: 50px;
  font-family: AlibabaPuHuiTiM;
  text-align: left;
  line-height: 69px;
  white-space: nowrap;
  display: block;
  padding-top: 20px;
}

.word6 {
  width: 18rem;
  padding-bottom:20px;
  overflow-wrap: break-word;
  color: rgba(0,0,0,0.4);
  font-size: 20px;
  font-family: Alibaba-PuHuiTi-R;
  text-align: left;
  line-height: 36px;
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.box4 {
  width: 428px;
  height: 64px;
  margin-top: 58px;
}

.section1 {
  height: 64px;
  background: linear-gradient(230deg, #198BFF 0%, #217AF9 100%);
  border-radius: 8px;
  width: 200px;
  cursor: pointer
}
.section1:hover{
  background: linear-gradient(360deg, #2b68ff 0%, #2b68ff 100%);
  transition: all .3s ease-in-out;
}
.info3 {
  width: 79px;
  height: 27px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: AlibabaPuHuiTiM;
  text-align: left;
  white-space: nowrap;
  line-height: 27px;
  display: block;
  margin: 19px 0 0 61px;
}.section2 {
  border-radius: 8px;
  height: 68px;
  border: 2px solid #2b68ff;
  width: 204px;
  margin: -2px -2px 0 0;
  cursor: pointer
}.txt4 {
  width: 79px;
  height: 27px;
  overflow-wrap: break-word;
  color: rgba(43, 104, 255, 1);
  font-size: 20px;
  font-family: AlibabaPuHuiTiM;
  text-align: left;
  white-space: nowrap;
  line-height: 24px;
  display: block;
  margin: 21px 0 0 63px;
}
 .banner1 {
 .section2:hover {
   background: none;
   transition: none;
 }
 }
.section2:hover,.section2:hover .txt4{
  background:#e6edff;
  transition: all .3s ease-in-out;
}
</style>
