<template>
  <div class="ab-home-swip flex-row justify-between">
    <a-carousel
      :afterChange="afterChange"
      :autoplay-speed="5000"
      autoplay
      dotsClass="ab-slick-dots"
      effect="fade"
      class="bannerContainer"
    >
      <div class="bannerItem banner1">
        <img
          class="banner_png"
          src="@/assets/home/banner/外贸极客.png"
          alt="外贸极客SCRM+AI 智能量化营销新模式 1 人就能产出传统模式3-5人的营销成果 使用无门槛，轻松易上手！"
        />
        <div class="bannerLabelContainer">
          <div class="bannerLabel warp">
            <div class="banner_title" data-aos="fade-down" data-aos-delay="50">
              外贸极客<span class="color_title">SCRM+AI</span>
            </div>
            <!-- <div class="banner_title_line"></div> -->
            <div
              class="banner_subtitle"
              data-aos="fade-down"
              data-aos-delay="50"
            >
              <div class="banner_subtitle_block"></div>
              <h1 style="position: relative;">
                智能量化营销新模式
              </h1>
              <div class="banner_sub_desc">
                <span class="color_title">1 人</span>就能产出传统模式<span
                  class="color_title"
                  >5-10人</span
                >的营销成果 使用无门槛，轻松易上手！
              </div>
            </div>
            <!-- <div class="banner_desc" data-aos="fade-down" data-aos-delay="100">
              助您轻松突破全球市场，突破业绩极限!
            </div> -->
            <div class="banner_tag" data-aos="fade-down" data-aos-delay="100">
              <div class="banner_tag_item">营销管理</div>
              <div class="banner_tag_item">推广引流</div>
              <div class="banner_tag_item">专业服务</div>
              <div class="banner_tag_item">客户管理</div>
              <div class="banner_tag_item">快速获客</div>
            </div>
            <div class="banner_btn" data-aos="fade-down" data-aos-delay="150">
              <a
                @click="showModal"
                data-source="p"
                onmouseover="linkDataSource(this)"
                a-type="register"
                target="_blank"
                ><div class="banner_btn_item banner_btn_item_primary">
                  立即体验
                </div>
              </a>
              <router-link to="/productService"
                ><div class="banner_btn_item">了解更多</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="bannerItem banner3">
        <img
          class="banner_png"
          src="@/assets/home/banner/数智化营销.png"
          alt="外贸推广引流 精准引流 智能投放 高效提升品牌曝光率和转化率 直击目标客户！全面支撑独立站、站群、Facebook、Linkedin等推广引流方式"
        />
        <div class="bannerLabelContainer">
          <div
            class="bannerLabel warp"
            data-aos="fade-down"
            data-aos-delay="50"
          >
            <div class="banner_title banner3_title">
              外贸推广引流
            </div>
            <h1
              class="banner_subtitle banner3_subtitle"
              data-aos="fade-down"
              data-aos-delay="50"
            >
              精准引流
              <span class="color_subtitle">智能投放</span>
            </h1>
            <div class="banner3_sub_desc">
              高效提升品牌曝光率和转化率 直击目标客户！
            </div>
            <div class="banner3_desc" data-aos="fade-down" data-aos-delay="100">
              全面支撑独立站、站群、Facebook、Linkedin等推广引流方式
            </div>
            <div class="banner3_tag" data-aos="fade-down" data-aos-delay="100">
              <div class="banner_tag_item">
                <a-icon type="check-circle" theme="filled" />智能创作
              </div>
              <div class="banner_tag_item">
                <a-icon type="check-circle" theme="filled" />智能建站
              </div>
              <div class="banner_tag_item">
                <a-icon type="check-circle" theme="filled" />智能SEO
              </div>
              <div class="banner_tag_item">
                <a-icon type="check-circle" theme="filled" />智能推广
              </div>
              <div class="banner_tag_item">
                <a-icon type="check-circle" theme="filled" />智能运营
              </div>
              <div class="banner_tag_item">
                <a-icon type="check-circle" theme="filled" />智能客服
              </div>
            </div>
            <div class="banner_btn" data-aos="fade-down" data-aos-delay="150">
              <a
                @click="showModal"
                data-source="p"
                onmouseover="linkDataSource(this)"
                a-type="register"
                target="_blank"
                ><div class="banner_btn_item banner_btn_item_primary">
                  立即体验
                </div>
              </a>
              <router-link to="/productService"
                ><div class="banner_btn_item">了解更多</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="bannerItem banner2">
        <img
          class="banner_png"
          src="@/assets/home/banner/外贸客户搜索.png"
          alt="外贸快速获客 集成海量客户数据、先进工具、智能算法等。为您打造快速获客神器，自动查询匹配高潜力客户，让每次营销都事半功倍。"
        />
        <div class="bannerLabelContainer">
          <div class="bannerLabel warp">
            <div
              class="banner_title banner2_title"
              data-aos="fade-down"
              data-aos-delay="50"
            >
              外贸快速获客
            </div>
            <h1
              class="banner_subtitle banner2_subtitle"
              data-aos="fade-down"
              data-aos-delay="50"
            >
              集成海量
              <span class="color_subtitle">客户数据、</span>
              <div>
                <span class="color_subtitle">先进工具、智能算法</span>
                等
              </div>
            </h1>
            <div class="banner2_desc" data-aos="fade-down" data-aos-delay="100">
              为您打造快速获客神器，自动查询匹配高潜力客户，让每次营销都事半功倍。
            </div>
            <div class="banner2_title_line"></div>
            <div class="banner2_tag" data-aos="fade-down" data-aos-delay="100">
              商机监控 ｜ 海关数据 ｜ 企业查询 ｜ 邮箱查询 ｜ Google地图 ｜
              Facebook搜索 ｜ Linkedin搜索
            </div>
            <div class="banner_btn" data-aos="fade-down" data-aos-delay="150">
              <a
                @click="showModal"
                data-source="p"
                onmouseover="linkDataSource(this)"
                a-type="register"
                target="_blank"
                ><div class="banner_btn_item banner_btn_item_primary">
                  立即体验
                </div>
              </a>
              <router-link to="/product/customs"
                ><div class="banner_btn_item">了解更多</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="bannerItem banner4">
        <img
          class="banner_png"
          src="@/assets/home/banner/智能高效建站.png"
          alt="外贸客户管理 业务全流程、客户全周期、多种通讯工具支撑 为您提供高效成交利器，智能化管理销售流程，助力业绩一路飙升"
        />
        <div class="bannerLabelContainer">
          <div
            class="bannerLabel warp"
            data-aos="fade-down"
            data-aos-delay="50"
          >
            <div class="banner_title banner4_title">
              外贸客户管理
            </div>
            <h1
              class="banner_subtitle banner4_subtitle"
              data-aos="fade-down"
              data-aos-delay="50"
            >
              外贸<span class="color_subtitle">业务全流程、</span>
              <div>
                <span class="color_subtitle">客户全周期、多种通讯工具</span>支撑
              </div>
            </h1>
            <div class="banner4_desc" data-aos="fade-down" data-aos-delay="100">
              为您提供高效成交利器，智能化管理销售流程，助力业绩一路飙升
            </div>
            <div class="banner4_tag" data-aos="fade-down" data-aos-delay="100">
              <span class="banner_tag_item">筛选分配</span>
              <span class="banner_tag_item">线索培育</span>
              <span class="banner_tag_item">商机管理</span>
              <span class="banner_tag_item">服务支持</span>
              <span class="banner_tag_item">全球电话</span>
              <span class="banner_tag_item">whatsapp</span>
              <span class="banner_tag_item">邮件</span>
            </div>
            <div
              class="banner_btn banner4_btn"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              <a
                @click="showModal"
                data-source="p"
                onmouseover="linkDataSource(this)"
                a-type="register"
                target="_blank"
                ><div class="banner_btn_item banner_btn_item_primary">
                  立即体验
                </div>
              </a>
              <router-link to="/productService"
                ><div class="banner_btn_item">了解更多</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="bannerItem banner5">
        <img
          class="banner_png"
          src="@/assets/home/banner/优惠活动.png"
          alt="外贸智能营销火热限时优惠活动 外贸极客 新品上市！特惠大酬宾，先到先得"
        />
        <div class="bannerLabelContainer">
          <div class="bannerLabel warp">
            <div
              class="banner_title banner5_title"
              data-aos="fade-down"
              data-aos-delay="50"
            >
              <!-- <span class="iconfont icon-redu"></span> -->
              点击注册<a
                @click="showModal"
                data-source="p"
                onmouseover="linkDataSource(this)"
                a-type="register"
                target="_blank"
                ><span class="banner5_title_btn">抢先体验!</span></a
              >下单立即享优惠
            </div>
            <div
              class="banner_subtitle banner5_subtitle"
              data-aos="fade-down"
              data-aos-delay="50"
            >
              外贸智能营销火热限时优惠活动
            </div>
            <div class="banner5_desc" data-aos="fade-down" data-aos-delay="100">
              外贸极客 新品上市！
            </div>
            <div class="banner5_tag" data-aos="fade-down" data-aos-delay="100">
              特惠大酬宾，先到先得
            </div>
            <div
              class="banner_btn banner5_btn"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              <a
                @click="showModal"
                data-source="p"
                onmouseover="linkDataSource(this)"
                a-type="register"
                target="_blank"
                ><div class="banner_btn_item banner_btn_item_primary">
                  立即体验
                </div>
              </a>

              <router-link to="/productService"
                ><div class="banner_btn_item">了解更多</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </a-carousel>
    <div class="banner_bottom_area">
      <div class="warp">
        <div class="banner_bottom_area_item" v-for="item in bannerTagList">
          <div class="banner_bottom_area_item_img">
            <!-- <img :src="item.icon" /> -->
            <div class="banner_bottom_area_item_title">
              <div>{{ item.title }}</div>
            </div>
          </div>
          <div class="banner_bottom_area_item_desc">{{ item.subTitle }}</div>
          <a
            @click="showModal"
            data-source="p"
            onmouseover="linkDataSource(this)"
            a-type="register"
            target="_blank"
            style="text-align: right;"
          >
            <div class="banner_bottom_area_item_btn">抢先体验</div>
          </a>
        </div>
      </div>
    </div>
    <inquiry-modal-vue
      :visible="visible"
      @cancel="handleCancel"
    ></inquiry-modal-vue>
  </div>
</template>

<script>
import icon1 from "@/assets/home/banner/智能营销.png";
import icon2 from "@/assets/home/banner/申请演示.png";
import icon3 from "@/assets/home/banner/客户查找.png";
import icon4 from "@/assets/home/banner/限时免费.png";
import inquiryModalVue from "@/components/InquiryModal2.vue";

export default {
  components: {
    inquiryModalVue,
  },
  //  mounted(){
  //   AOS.init();
  // }
  data() {
    return {
      bannerTagList: [
        {
          title: "限时特惠，机会难得",
          subTitle:
            "新品外贸极客超低价限时抢购，开启外贸营销新时代，零风险无忧起步！",
          icon: icon1,
        },
        {
          title: "注册即享豪礼",
          subTitle:
            "免费赠送海关数据、精准邮箱挖掘、智能助手、专业营销邮件等多项VIP功能！",
          icon: icon2,
        },
        {
          title: "免费CRM一年，尽情体验",
          subTitle:
            "注册认证即送一年免费CRM，全方位客户管理，助您高效运营和快速转化！",
          icon: icon3,
        },
      ],
      visible: false,
    };
  },
  methods: {
    afterChange(e) {
      // if (e == 3) {
      //   document.querySelector(".header-bg").classList.add("blueHeader");
      //   document.querySelector(".header-bg").classList.remove("tradeHeader");
      // } else if (e == 0) {
      //   document.querySelector(".header-bg").classList.add("tradeHeader");
      //   document.querySelector(".header-bg").classList.remove("blueHeader");
      // } else {
      //   // document.querySelector(".header-bg").classList.remove("redHeader");
      //   document.querySelector(".header-bg").classList.remove("blueHeader");
      //   document.querySelector(".header-bg").classList.remove("tradeHeader");
      // }
    },
    showModal() {
      window.open("https://crm.cnabke.com/#/passport/register", "_blank");
      this.visible = true;
    },

    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="sass" scoped>

.banner_1{
  em{
    i{
      &:hover{
        background: rgba(255, 255, 255, 0.9);
      }
      padding: 0 0.4rem;
    line-height: 0.96rem;
    text-align: center;
    font-size: 0.48rem;
    color: #2B68FF;
    margin-right: 0.36667rem;
    font-family: 'Alibaba-PuHuiTi-R';
    display: inline-block;
    background: rgba(255, 255, 255, 0.5);
    }
  }
  position: relative;
  margin:0px auto;
  width:1374px !important;
  display: block !important;
}
::v-deep .ant-carousel .slick-initialized .slick-slide{
  height:650px
}
.subTitle {
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
font-weight: normal;
font-size: 36px;
color: rgba(0, 0, 0, 0.5);
line-height: 42px;
display: inline-block;
width: 543px;
border-bottom: 2px solid rgba(51,51,51,0.2);
padding-bottom:20px;
margin-top: 20px;
}
.banner_1 img{
  /*width:553px;*/
}
.banner_title_img {
  width: 700px;
  margin-top: 30px;
}
.banner_btn1 {
  width: 280px;
}
.banner1_img {
  /*top: 29px;*/
  /*right: 87px;*/
  /*transform: scale(1.4);*/
  width: 750px !important;
}
.banner_img{
  position: absolute;
  top: 0px;
  right: 0px;
  width:553px;
  max-height: 550px;
}
  .ab-home-swip{
    width: 100%;
    // height: 612px;
    margin:0px auto;
      position: relative;
      .banner_bottom_area {
        position: absolute;
        bottom: -120px;
        width: 100%;
        .warp {
          display: flex;
          column-gap: 26px;
          padding: 0 100px;
          .banner_bottom_area_item {
            cursor: pointer;
            flex: 1;
            background: #FFFFFF;
            border-radius: 10px;
border: 1px solid #FF6600;
box-shadow: 0px 3px 14px 0px rgba(255, 102, 0, 0.4);
padding: 25px;
flex-direction: column;
row-gap: 8px;
display: flex;
// align-items: center;
.banner_bottom_area_item_img {
  display: flex;
  column-gap: 10px;
  align-items: center;
  .banner_bottom_area_item_title {
    font-family: AlibabaPuHuiTiM;
    font-size: 23px;
color: #FF6600;
line-height: 23px;
margin-bottom: 8px;
    }
  img {
    width: 40px;
    height: 40px;
    }
  }
    .banner_bottom_area_item_btn {
      background: linear-gradient( 67deg, #FF6600 0%, #FFB10F 100%);
      text-align: center;
      padding: 6px 20px;
      border-radius: 20px;
      color: #fff;
      display: inline-block;
      font-size: 16px;
      }
.banner_bottom_area_item_desc {
  font-weight: 400;
font-size: 19px;
color: #222222;
line-height: 28px;
  }
            }
          }
        }
    // margin-top:50px;
      .bannerContainer {
        width: 100%;
        // height: 100%;
        height: 650px;

        .banner1 {
          // background: url('~@/assets/home/banner/外贸极客.png');
          }
          .banner2 {
          // background: url('~@/assets/home/banner/外贸客户搜索.png');
          }
          .banner3 {
          // background: url('~@/assets/home/banner/数智化营销.png');
          }
          .banner4 {
          // background: url('~@/assets/home/banner/智能高效建站.png');
          }
          .banner5 {
          // background: url('~@/assets/home/banner/优惠活动.png');
          }
        .bannerItem {
          position: relative;
          width: 100%;
          height: 650px;
          background-size: cover;
          background-position: -300px 0;
          .banner_png {
            width: 100%;
            height: 100%;
            object-fit: cover;
            }
              .bannerLabelContainer {
                position: absolute;
                width: 100%;
    top: 0px;
                }
          .bannerLabel {
            font-family: "Alibaba-PuHuiTi-R";
            padding: 100px 0 0 100px;
            .banner_title {
              font-family: AlibabaPuHuiTiM;
                      font-weight: 500;
font-size: 30px;
line-height: 38px;
color: #333646;
.color_title {
  background-image: linear-gradient(0.7112178312210219deg, #333646 0%, #256FF2 5%, #3986F6 43%, #5DC0F5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
              }
                .banner2_title {
color: #26276C;
}
                .banner3_title {
color: #26276C;
                  }
                    .banner4_title {
                      font-weight: 500;
font-size: 32px;
color: #1C2C71;
line-height: 44px;
                      }
                        .banner5_title {
                          font-size: 18px;
color: #FFFFFF;
line-height: 30px;
.iconfont {
  font-size: 34px;
  }
    a:hover {
      color: #fff;}
    .banner5_title_btn {
      width: 91px;
height: 31px;
font-size: 18px;
color: #FFFFFF;
background: linear-gradient( 131deg, #FF8438 0%, #FFB24D 100%);
border-radius: 15px;
text-align: center;
padding: 0 4px;
margin: 0  10px;

      }
                          }
                .banner_title_line{
                  height: 1px;
                  background: #BCC5CD;
                  width: 273px;
                  margin: 16px 0;
                  }
                    .banner_subtitle {
                      font-family: AlibabaPuHuiTiM;
                      font-weight: 500;
                      position: relative;
                      font-size: 44px;
color: #26276C;
line-height: 70px;
margin: 30px 0 0px 0;
h1 {
  font-size: 44px;
  }
.banner_sub_desc {
  margin-top: 20px;
  width: 500px;
  font-size: 28px;
  line-height: 50px;
  // font-family: Alibaba-PuHuiTi-R;
                      font-weight: 500;
                      .color_title {
                        color:#0680F2;
                        }
  }
.banner_subtitle_block {
  position: absolute;
  top: 35px;
  left: 170px;
  width: 142px;
height: 26px;
background: linear-gradient( 90deg, #3A87F7 0%, #78C8FE 55%, rgba(120,200,254,0) 99%);
opacity: 0.6;
  }
                      }
                        .banner2_title_line {
                          border: 1px solid;
border-image: linear-gradient( 90deg, #084583 0%, #0E7BE9 100%) 1 1;
                          height: 0px;
                          width: 680px;
                          }
                        .banner2_subtitle {
                          margin: 30px 0 10px 0;
                          // font-weight: 600;
                          font-size: 44px;
color: #26276C;
line-height: 70px;
.color_subtitle {
  // background: linear-gradient( 81deg, #C0DEFE 0%, rgba(179,214,253,0) 14%, rgba(172,210,253,0) 29%, rgba(139,190,252,0) 52%, rgba(138,188,253,0.3) 76%, #B7D6FF 100%);
  background-image: linear-gradient( 275deg, #0C92FF 0%, #1C6CF7 100%);
   background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // margin-left: 15px;
  }
                          }
                            .banner3_subtitle {
                              margin: 30px 0 0px 0;
.color_subtitle {
  // background: linear-gradient( 81deg, #C0DEFE 0%, rgba(179,214,253,0) 14%, rgba(172,210,253,0) 29%, rgba(139,190,252,0) 52%, rgba(138,188,253,0.3) 76%, #B7D6FF 100%);
  background-image: linear-gradient( 94deg, #0F8AF3 0%, #155FEC 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
                              }
                                .banner3_sub_desc {
                                  font-size: 28px;
color: #26276C;
line-height: 60px;
font-family: AlibabaPuHuiTiM;
                      font-weight: 500;
                                  }
                                .banner4_subtitle {
                                  margin: 20px 0 10px 0;
                                  // width: 582px;
                                  .color_subtitle {
  background-image: linear-gradient( 94deg, #0F8AF3 0%, #155FEC 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
                                  // .banner_subtitle_item {
                                  //   width: 183px;
                                  //   border-radius: 0 4px 4px 0;
                                  //   border: 1px solid #3B58B4;
                                  //   font-size: 16px;
                                  //   color: #203F90;
                                  //   padding: 5px 10px;
                                  //   }
                                  //     .banner_subtitle_item_primary {
                                  //       background: linear-gradient( 90deg, #3952A7 0%, #173988 100%);
                                  //       color: #fff;
                                  //       border-radius: 4px 0px 0px 4px;
                                  //       }
                                  }
                                    .banner5_subtitle {
                                      width: 489px;
                                      margin: 20px 0 30px 0;
font-size: 56px;
color: #FFFFFF;
line-height: 77px;
letter-spacing: 6px;
text-shadow: 6px 6px 0px rgba(244, 244, 244, 0.2);
.color_subtitle {
  background-image: linear-gradient(359.2156831709328deg, #76DBFB 0%, #FFFFFF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
                                      }
                        .banner_desc {
                          font-size: 18px;
color: #222222;
line-height: 50px;
width: 594px;
margin: 15px 0;
                          }
                            .banner2_desc {
                              font-size: 18px;
color: #26276C;
line-height: 21px;
width: 630px;
margin-bottom: 26px;
                              }
                                .banner3_desc {
                                  font-size: 18px;
color: #181E58;
line-height: 21px;
width: 555px;
margin: 6px 0 30px 0;
                                  }
                                    .banner4_desc {
                                      font-size: 18px;
color: #1C2C71;
line-height: 21px;
margin-bottom: 24px;
                                      }
                                        .banner5_desc {
                                          margin-bottom: 10px;
                                          font-size: 34px;
color: #FFFFFF;
line-height: 47px;
                                          }
                          .banner_tag {
                            display: flex;
                            flex-wrap: wrap;
                            width: 658px;
                            row-gap: 30px;
                            font-size: 16px;
                            color: #425261;
                            margin: 30px 0 40px 0;
                            .banner_tag_item {
                              width: 19%;
                              display: flex;
                              column-gap: 10px;
                              align-items: center;
                              }
                              .banner_tag_item::before {
                                content: '';
                                width: 6px;
height: 18px;
background: linear-gradient( 180deg, #57AEF9 0%, #538FFA 100%);
border-radius: 1px 1px 1px 1px;
display: inline-block;
clip-path: polygon(0% 0%, 100% 5%, 100% 100%, 0% 95%);

                          }
                            }
                              .banner2_tag {
                                margin: 24px 0 60px 0;
                                font-size: 14px;
color: #0C1669;
                                .clash {
                                  margin: 0 8px;
                                  font-size: 14px;
color: rgba(12, 22, 105, 0.30);
                                  }
                                }
                                  .banner3_tag {
                                    width: 580px;
                                    font-size: 14px;
display: flex;
                            flex-wrap: wrap;
                            row-gap: 12px;
                            margin: 30px 0 60px 0;
                            color: rgba(24,30,88,0.8);

.banner_tag_item {
  width: 33%;
  display: flex;
  column-gap: 10px;
  align-items: center;
  .anticon {
    color: rgba(0,0,0,0.6);
    }
  }
                                    }
                                      .banner4_tag {
                                        font-size: 16px;
                                        color: #000000;
line-height: 30px;
width: 500px;
margin: 30px 0;
.clash {
  margin: 0 30px;
  color: rgba(100,106,115,0.3);
  }
    .banner_tag_item {
      display: inline-block;
      margin-bottom: 15px;
      width: 24%;
      }
        .banner_tag_item::before {
          content: '';
          width: 6px;
height: 6px;
background: #0EB7FF;
border-radius: 50%;
display: inline-block;
margin-right: 6px;
margin-bottom: 3px;
                                          }
                                        }
                                          .banner5_tag {
                                            font-size: 22px;
color: #F6F6F6;
line-height: 30px;
margin-bottom: 70px;
                                            }
                              .banner_btn {
                                display: flex;
                                column-gap: 16px;
                                .banner_btn_item {
                                  width: 223px;
                                  height: 55px;
                                  line-height: 55px;
                                  text-align: center;
                                  border-radius: 8px;
                                  border: 1px solid #0076FF;
                                  font-weight: 500;
                                  font-family: AlibabaPuHuiTiM;
                                  font-size: 18px;
                                  cursor: pointer;
                                  color: #0076FF;

                                  }
                                  .banner_btn_item_primary {
                                    background: #0076FF;
                                    color: #fff;
                                    }
                                }
                                  .banner4_btn {
                                    .banner_btn_item_primary {
                                    background: #1F3983;
                                    color: #fff !important;
                                    }
                                    .banner_btn_item {
                                      border: 1px solid #1F3983;
                                      color: #1F3983;
                                    }
                                    }
                                  .banner5_btn {
                                    .banner_btn_item {
                                      border: 1px solid #fff;
                                      color: #fff;
                                      }
                                        .banner_btn_item_primary {
                                          background: linear-gradient( 84deg, #2D7BF9 0%, #76D7FE 100%);
border-radius: 8px;
                                          border: none;
                                          color: #fff;
                                          }
                                    }
            }

          }
        }

    .ab-home-swip-left {
      width: 100%;
      height: 600px;
      margin-top: 12px;

      // .ab-slick-dots{
      //   text-align: left;

      //   li{
      //     width: 43px;
      //     height: 5px;
      //     background: #D8D8D8;
      //     border-radius: 2px;
      //     margin-right:10px;

      //     button{
      //       width: 43px;
      //       height: 5px;
      //       border-radius: 2px;
      //     }

      //   }
      //   .slick-active{
      //     button{
      //       background: linear-gradient(230deg, #198BFF 0%, #217AF9 100%) !important;
      //       width: 43px;
      //     }

      //   }
      // }
    }

  }

  ::v-deep .ant-carousel .slick-dots{
    text-align: left;
  }

  ::v-deep .ant-carousel .slick-dots li{
    width: 43px;
    height: 5px;
    background: #D8D8D8;
    border-radius: 2px;
    margin-right:10px;
  }

  ::v-deep .ant-carousel .slick-dots li button{
    width: 43px;
    height: 5px;
    border-radius: 2px;
  }

  ::v-deep .ant-carousel .slick-dots li.slick-active button{
    background: linear-gradient(230deg, #198BFF 0%, #217AF9 100%);
    // background: linear-gradient(230deg, #198BFF 0%, #217AF9 100%) !important;
    width: 43px;
  }
  @media screen and (min-width: 1400px) and (max-width: 1800px) {
    .banner_bottom_area {
      bottom: -150px !important; }
}
@media screen and (min-width: 1800px) and (max-width: 2200px) {
    .banner_bottom_area {
      bottom: -160px !important;}
}
@media screen and (min-width: 2200px) {
    .banner_bottom_area {
      bottom: -170px !important;}
}
</style>
