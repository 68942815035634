<template>
  <div class="home_tab_two_container">
    <div class="tab_title" data-aos="fade-down" data-aos-delay="50">
      洞察目标市场和受众
    </div>
    <div class="home_tab_one warp">
      <div class="home_tab_one_left" data-aos="fade-down" data-aos-delay="100">
        <div
          :class="
            `${
              tabValue === index
                ? 'home_tab_one_item_active home_tab_one_item'
                : 'home_tab_one_item'
            }`
          "
          v-for="(item, index) in tabList"
          :key="item.title"
          @click="changeTab(item, index)"
        >
          <div class="home_tab_one_item_title">
            <span :class="`${item.icon} iconfont`"></span>
            <div class="home_tab_one_item_title_value">{{ item.title }}</div>
          </div>
          <div v-if="tabValue === index" class="home_tab_one_item_subtitle">
            {{ item.subTitle }}
          </div>
        </div>
      </div>
      <div class="home_tab_one_right" data-aos="fade-down" data-aos-delay="100">
        <div class="home_tab_one_right_img">
          <img :src="currentImg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img7 from "@/assets/home/截图/7.png";
import img8 from "@/assets/home/截图/8.png";

export default {
  components: {},
  data() {
    return {
      tabValue: 0,
      currentImg: img7,
      tabList: [
        {
          icon: "icon-locate",
          img: img7,
          title: "挖掘目标市场",
          subTitle:
            "借助前沿数据分析与行业洞察，外贸极客精准锁定全球商机。我们深入挖掘目标市场，覆盖市场规模、特点、发展趋势等多维度信息，优化您的市场营销策略，确保您在激烈竞争中脱颖而出。选择外贸极客，掌控市场先机，驱动业务增长。",
        },
        {
          icon: "icon-support",
          img: img8,
          title: "定位目标受众",
          subTitle:
            "外贸极客精准定位您的目标受众，深入挖掘包括受众特点、规模、偏好、经济状况和技术适应等关键数据。通过多样化的获客方式和定制化的沟通技巧，确保高效触达潜在客户。同时，我们了解各地风俗文化，帮助您在商务谈判中占据有利位置。让“外贸极客”成为您的市场拓展利器，助您轻松赢得全球客户！",
        },
      ],
    };
  },
  methods: {
    changeTab(data, e) {
      this.tabValue = e;
      this.currentImg = data.img;
    },
  },
};
</script>

<style lang="sass" scoped>
.home_tab_two_container {
  margin-top: 90px;
  .tab_title {
    margin-bottom: 54px;
    font-weight: 500;
    font-family: AlibabaPuHuiTiM;
font-size: 36px;
color: #111111;
text-align: center;
    }
      .home_tab_one {
        display: flex;
        column-gap: 76px;
        padding-bottom: 120px;
        .home_tab_one_left {
          display: flex;
          flex-direction: column;
          row-gap: 30px;
          .home_tab_one_item {
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            cursor: pointer;
            padding-left: 26px;
            .home_tab_one_item_title {
              display: flex;
              column-gap: 10px;
              font-weight: 500;
              font-family: AlibabaPuHuiTiM;
font-size: 28px;
color: #222222;
align-items: center;
.iconfont {
  font-size: 32px;
  }
              }
              .home_tab_one_item_subtitle {
  color: #646A73;
  font-size: 16px;
  width: 390px;
  }
            }
              .home_tab_one_item_active {
                background: #FFFFFF;
box-shadow: 0px 4px 10px 0px rgba(125,150,219,0.1);
border-radius: 12px;
padding: 20px 110px 20px 26px;
color: #0680F2;
.home_tab_one_item_title {
  color: #0680F2;
  }
                }

          }
        .home_tab_one_right {
          flex: 1;
          .home_tab_one_right_img img {
            width:100%;}
          }
        }
          .home_tab_two_faq {
            background: #FFFFFF;
            padding: 100px 0 50px 0;
            .faq_tip {
              font-size: 18px;
color: #111111;
justify-content: center;
margin-bottom: 10px;
display: flex;
align-items: center;
column-gap: 8px;
img {
  width: 80px;
  }
              }
            }
  }
</style>
