<template>
  <div class="home_tab_two_container">
    <div class="tab_title" data-aos="fade-down" data-aos-delay="50">
      客户和产品营销双驱动
    </div>
    <div class="home_tab_one warp">
      <div class="home_tab_one_left" data-aos="fade-down" data-aos-delay="100">
        <div
          :class="
            `${
              tabValue === index
                ? 'home_tab_one_item_active home_tab_one_item'
                : 'home_tab_one_item'
            }`
          "
          v-for="(item, index) in tabList"
          :key="item.title"
          @click="changeTab(item, index)"
        >
          <div class="home_tab_one_item_title">
            <span :class="`${item.icon} iconfont`"></span>
            <div class="home_tab_one_item_title_value">{{ item.title }}</div>
          </div>
          <div v-if="tabValue === index" class="home_tab_one_item_subtitle">
            {{ item.subTitle }}
          </div>
        </div>
      </div>
      <div class="home_tab_one_right" data-aos="fade-down" data-aos-delay="100">
        <div class="home_tab_one_right_img">
          <img :src="currentImg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import img9 from "@/assets/home/截图/9.png";
// import img10 from "@/assets/home/截图/10.png";

export default {
  components: {},
  data() {
    return {
      tabValue: 0,
      currentImg: "",
      tabList: [
        {
          icon: "icon-a-Group34995",
          // img: img9,
          title: "产品驱动",
          subTitle:
            "外贸极客通过全面的产品数据和智能营销推广手段，帮助您精准把握市场动向。我们整合产品基础信息、优势特点和市场资讯，并提供丰富的营销话术和关键词库，全方位提升您的产品推广效果。运用先进AI技术，自动生成高效获客方案，让您的产品直击目标市场和受众，实现订单业绩的持续增长。",
        },
        {
          icon: "icon-contactInfoIds",
          // img: img10,
          title: "客户驱动",
          subTitle:
            "外贸极客通过深度数据整合与分析，全方位掌控客户动态。我们提供详细的客户基础信息、标签和意向产品数据，并结合海关记录和客户日志，生成智能跟进计划。运用AI技术，提供精准的客户评估和开发方案，大大提升您的客户转化率。实现精准营销，全程智能跟进，助力业绩腾飞。",
        },
      ],
    };
  },
  methods: {
    changeTab(data, e) {
      this.tabValue = e;
      this.currentImg = data.img;
    },
  },
};
</script>

<style lang="sass" scoped>
.home_tab_two_container {
  margin-top: 90px;
  .tab_title {
    margin-bottom: 54px;
    font-weight: 500;
    font-family: AlibabaPuHuiTiM;
font-size: 36px;
color: #111111;
text-align: center;
    }
      .home_tab_one {
        display: flex;
        column-gap: 76px;
        padding-bottom: 120px;
        .home_tab_one_left {
          display: flex;
          flex-direction: column;
          row-gap: 30px;
          .home_tab_one_item {
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            cursor: pointer;
            padding-left: 26px;
            .home_tab_one_item_title {
              display: flex;
              column-gap: 10px;
              font-weight: 500;
              font-family: AlibabaPuHuiTiM;
font-size: 28px;
color: #222222;
align-items: center;
.iconfont {
  font-size: 32px;
  }
              }
              .home_tab_one_item_subtitle {
  color: #646A73;
  font-size: 16px;
  width: 390px;
  }
            }
              .home_tab_one_item_active {
                background: #FFFFFF;
box-shadow: 0px 4px 10px 0px rgba(125,150,219,0.1);
border-radius: 12px;
padding: 20px 110px 20px 26px;
color: #0680F2;
.home_tab_one_item_title {
  color: #0680F2;
  }
                }

          }
        .home_tab_one_right {
          flex: 1;
          .home_tab_one_right_img img {
            width:100%;}
          }
        }
  }
</style>
