import FingerprintJS from '@fingerprintjs/fingerprintjs';
// import FingerprintJS from 'fingerprintjs';

const getUniqueCode = async () => {
  // const fp = await FingerprintJS.load({screen_resolution:true});

  // const result =await fp.get();

  // const visitorId = result.visitorId;
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  console.log(result, result.visitorId, '======FingerprintJS======');
  const uuid = result.visitorId;
  console.log(uuid, '===uuid===FingerprintJS======');
  return uuid;
};
export default getUniqueCode;
