import getUniqueCode from './uuid'

const behaviorUpdate = (e) => {
  // let url = process.env.VUE_APP_BASE_API + '/app-crm/data/user/gw/create'

  let devUrl = 'https://dev-plat.cnabke.com'
  let testUrl = 'https://test-plat.cnabke.com'
  let masterUrl = 'https://plat.cnabke.com'
  let nowUrl = ''
  let url = '/app-crm/data/user/gw/create'
  // let userUpdateData = ''
  if (window.location.hostname.indexOf('dev-') !== -1) {
    nowUrl = devUrl + url
  } else if (window.location.hostname.indexOf('test') !== -1 || window.location.hostname.indexOf('localhost') !== -1) {
    nowUrl = testUrl + url
  } else {
    nowUrl = masterUrl + url
  }
  
  // console.log(nowUrl, '========behaviorUpdatebehaviorUpdate==========');
  let log = JSON.parse(sessionStorage.getItem('userBehaviorLog'))
  log.useTime = new Date().getTime() - log.startTime
  log = JSON.stringify(log)
  sessionStorage.removeItem('userBehaviorLog')
  window.localStorage.removeItem('urlArr')
  return navigator.sendBeacon(nowUrl, log);
}

const getQueryVariable = (variable) => {
  let query = window.location.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
      if (pair[0] == variable && pair[1] !== 'undefined') {
    if(pair[1].indexOf("%") > -1){
      pair[1] = decodeURI(pair[1])
    }
          return pair[1]
      }
  }
  return (false)
}

const UserActiveLog =  {
  startTime: new Date().getTime(), // 进入页面时间
  visitorId: '', // 浏览器指纹
  timer: 0, // 用户离开时间计时器
  timeNum : 0 ,// 用户离开时间 (秒)
  constructor(){
    // console.log('========constructorconstructorconstructor==========', this.startTime);
    // window.addEventListener('beforeunload', behaviorUpdate, false);
    // this.initFingerprintJS()
    this.bindBehaviorEvent()
    this.leaveTimer()
  },

  onBehaviorUpdate(from){
    behaviorUpdate();
  },

  setUserBehaviorLogField(fields){
    // console.log(fields, sessionStorage.getItem('userBehaviorLog'), '--------fieldsfieldsfields------------');
    if (sessionStorage.getItem('userBehaviorLog')) {
        // 有缓存就读缓存，然后向缓存里添加新的数据
        let log = JSON.parse(sessionStorage.getItem('userBehaviorLog'))
        // for (const item of fields) {
        //   log[key] = val;
        // }
        
        Object.keys(fields).forEach((key) => {
          log[key] = fields[key];
          // log.sourcePageName = urlPath;
        });
        // console.log(log, '--------loglog--------');

        let userUpdateData = JSON.stringify(log)
          sessionStorage.setItem('userBehaviorLog', userUpdateData)
      }
  },

  /**
         * 计算用户离开时间
         * 超过30分钟自动上传用户记录
         * */
  leaveTimer() {
    let that = this
    this.timer = setInterval( () => {
        that.timeNum++
        if (this.timeNum >= 60 * 30) {
        //    用户离开30分钟
            behaviorUpdate()
            that.timeNum = 0
            clearInterval(that.timer)
        }
    }, 1000)
  },
  /**
   * 创建默认用户轨迹缓存方法
   * */
 async initFingerprintJS() {
      // Initialize an agent at application startup.
      // const fpPromise = FingerprintJS.load()
      // Get the visitor identifier when you need it.
      // fpPromise.then(function(fp){ return fp.get()}).then( function(result) {
        const uuid = await getUniqueCode();
        console.log("uuid", uuid);
          this.visitorId = uuid
          //   const pageId = document.getElementById('pageId').value
      //   const inquiryPackageId =
      //     document.getElementById('inquiryPackageId').value
      //   const websiteId = document.getElementById('websiteId').value
        var browser = getBrowserInfo()
        var system = getSystem(),
        browser = browser[0].substring(0, browser[0].indexOf('/') + 3)
        browser = browser.replace('/', '_')
        console.log(this);
        let urlPath = window.location.pathname;
        urlPath = urlPath.replace(/^\//,'');
        urlPath = urlPath.replaceAll('/','_');
        const log = {
          
          startTime: new Date().getTime(),
          browser: browser,
          browserSize: window.innerWidth + '*' + window.innerHeight,
          browserVersion: browser,
          core: '',
          domainName: window.location.host,
          // domainName: window.location.href.split('?')[0],
          functions: [],
          isStart:
            document.referrer.indexOf(window.location.href.split('?')[0]) ===
            -1,
          isSuccess: false,
          pageCode: urlPath,
          pageName: urlPath,
          requester: this.visitorId,
          sourcePageCode: document.referrer,
          sourcePageName: document.referrer,
          system: system,
          useTime: 0,
          // websiteId: websiteId,
          // inquiryPackageId: inquiryPackageId,
          // pageId: pageId,
        }
        let userUpdateData = JSON.stringify(log)
        sessionStorage.setItem('userBehaviorLog', userUpdateData)
        // })
      

  },
  /**
   * 绑定用户行为事件（默认）
   */
  bindBehaviorEvent() {

      // document.removeEventListener('click',(event) => {}, true);
      document.addEventListener('click', (event) => {
        event.stopImmediatePropagation();
          let that = this
          try {
              clearInterval(this.timer)
              that.timeNum = 0
              that.leaveTimer()
              let pathArr = event.path || (event.composedPath && event.composedPath());
              // console.log(pathArr, '====pathArr');
              let target;
              let isStatic = false
              for (let i = 0; i < pathArr.length; i++) {
                  isStatic = that.isUserBehavior(pathArr[i])
                  if (isStatic) {
                      target = pathArr[i];
                      break
                  }
              }
              let btnText = event.target.innerText ? event.target.innerText : event.target.value;
              if (isStatic && btnText) {
                  // 去除开头结尾换行符
                  btnText = btnText.replace(/[\n\r]/g, '');
                  // if (btnText && btnText.indexOf('（')) {
                  //     btnText = btnText.split('（')[0];
                  // }
                  //使用json格式，以后方便扩展其他数据 比如加入自定义标签属性等
                  that.saveLog({
                      optName: btnText.trim()
                  });
              }
          }catch (e) {
              console.log(e)
          }

      }, false);
  },

  /**
   * 判断是否为公用的元素 用于记录用户行为
   * @param target 点击对象
   */
  isUserBehavior(target) {
      // 冒泡到document事件容错
      if (target.nodeName !== undefined) {
          if (target.nodeName.toLowerCase() === 'a') return true;
          if (target.nodeName.toLowerCase() === 'button' || target.type === 'button') return true;
          // if (target.className && (target.className.indexOf('ant-tabs-tab') > -1 || target.className.indexOf('screenItem') > -1 )) return true;
      }
  },

  /**
   * 用户行为记录保存到本地
   * @param  behaviorInfo 在点击按钮时，传入的用户点击信息
   */
  saveLog(behaviorInfo) {
      const sourcePageCode = getQueryVariable('sourcePageCode')
      const sourcePageName = getQueryVariable('sourcePageName')
      if (sessionStorage.getItem('userBehaviorLog')) {
          // 有缓存就读缓存，然后向缓存里添加新的数据
          let log = JSON.parse(sessionStorage.getItem('userBehaviorLog'))
          log.functions.push(behaviorInfo.optName)
          let userUpdateData = JSON.stringify(log)
          sessionStorage.setItem('userBehaviorLog', userUpdateData)
      }else {
          // 没有缓存 说明是新进入页面，创建数据，写入缓存
          this.startTime = new Date().getTime()
          this.initFingerprintJS
      }
  },

  urlCache() {
    // 进入过那些页面存在缓存里
    let urlPath = window.location.pathname;
        urlPath = urlPath.replace(/^\//,'');
        urlPath = urlPath.replaceAll('/','_');
    if(window.localStorage.getItem('urlArr')){
      let urlArr=JSON.parse(window.localStorage.getItem('urlArr'))
      if(Object.prototype.toString.call(urlArr) === '[object String]'){
          urlArr=JSON.parse(urlArr)
      }
      urlArr.push({sourcePageCode:urlPath, sourcePageName:urlPath})
      window.localStorage.setItem('urlArr', JSON.stringify(urlArr))
  } else {
      window.localStorage.setItem('urlArr', JSON.stringify('[]'))
      let urlArr=JSON.parse(window.localStorage.getItem('urlArr'))
      if(Object.prototype.toString.call(urlArr) === '[object String]'){
          urlArr=JSON.parse(urlArr)
      }
      urlArr.push({sourcePageCode:urlPath, sourcePageName:urlPath})
      window.localStorage.setItem('urlArr', JSON.stringify(urlArr))
  }
  },
  openNewHtml(id) {
    let url = window.location.origin + '/school/content/' + id + '.html'
    window.open(url, '_self')
  }

}

// const userLog = Object.create(UserActiveLog);
// export default userLog
export default UserActiveLog